@import "../../ComponentStyles/components-styles.scss";

$header-heading-size: 64px;
$header-heading-size-md: 52px;
$header-heading-size-sm: 40px;
$header-heading-size-xs: 28px;

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-header {
    height: 400px;
    background-image: url("../../../static/header-2.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    .header-content {
      color: $color-yellow;
      h1 {
        font-size: $header-heading-size-xs;
        text-align: center;

        #highlight {
          background-color: $color-yellow;
          color: $color-gray;
        }
      }
      #header-about {
        font-size: $text-font-size-mb;
        text-align: center;
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-header {
    height: 400px;
    background-image: url("../../../static/header-2.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    .header-content {
      color: $color-yellow;
      h1 {
        font-size: $header-heading-size-sm;
        text-align: center;

        #highlight {
          background-color: $color-yellow;
          color: $color-gray;
        }
      }
      #header-about {
        width: 85%;
        margin: 0 auto;
        font-size: $heading-5-font-size-lg;
        text-align: center;
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-header {
    height: 500px;
    background-image: url("../../../static/header-2.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    .header-content {
      color: $color-yellow;
      h1 {
        font-size: $header-heading-size-md;
        text-align: center;

        #highlight {
          background-color: $color-yellow;
          color: $color-gray;
        }
      }
      #header-about {
        font-size: $heading-5-font-size-lg;
        text-align: center;
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-header {
    height: 800px;
    background-image: url("../../../static/header-2.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    .header-content {
      color: $color-yellow;
      h1 {
        font-size: $header-heading-size;
        text-align: center;

        #highlight {
          background-color: $color-yellow;
          color: $color-gray;
        }
      }
      #header-about {
        width: 85%;
        margin: 0 auto;
        font-size: $heading-4-font-size-lg;
        text-align: center;
      }
    }
  }
}
