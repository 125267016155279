@import "../../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-aaa-description {
    padding: 10px;
    margin: 40px auto;
    h1 {
      font-size: $heading-1-font-size-mb;
      text-align: center;
    }
    .description {
      font-size: $text-font-size-mb;
      text-align: center;
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-aaa-description {
    padding: 10px;
    margin: 40px auto;
    h1 {
      font-size: $heading-1-font-size-mb;
      text-align: center;
    }
    .description {
      font-size: $text-font-size-mb;
      text-align: center;
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-aaa-description {
    padding: 20px;
    margin: 40px auto;
    h1 {
      font-size: $heading-1-font-size-lg;
      text-align: center;
    }
    .description {
      font-size: $text-font-size-lg;
      text-align: center;
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-aaa-description {
    padding: 20px;
    width: 90%;
    margin: 40px auto;
    h1 {
      font-size: $heading-1-font-size-lg;
      text-align: center;
    }
    .description {
      font-size: $text-font-size-lg;
      text-align: center;
    }
  }
}
