@import "../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-about-us-section {
    margin: 80px auto;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 40px;
    .left {
      text-align: center;
      .logo-heading {
        color: $color-yellow;
        font-size: $heading-2-font-size-mb;
      }
      .tagline-heading {
        font-size: $heading-2-font-size-mb;
        margin: 10px 0;
      }
      .about_listing_part {
        margin: 30px 20px;
        list-style-type: none;
      }
    }
    .right {
      padding: 10px;
      img {
        width: 100%;
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-about-us-section {
    margin: 80px auto;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 40px;
    .left {
      text-align: center;
      .logo-heading {
        color: $color-yellow;
        font-size: $heading-2-font-size-mb;
      }
      .tagline-heading {
        font-size: $heading-2-font-size-mb;
        margin: 10px 0;
      }
      .about_listing_part {
        margin: 30px 20px;
        list-style-type: none;
      }
    }
    .right {
      right: 10px;
      img {
        width: 100%;
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-about-us-section {
    margin: 80px auto;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    align-items: center;

    .left {
      .logo-heading {
        color: $color-yellow;
        font-size: $heading-2-font-size-lg;
      }
      .tagline-heading {
        font-size: $heading-1-font-size-lg;
        margin: 10px 0;
      }
      .about_listing_part {
        margin: 30px 20px;
      }
    }
    .right {
      padding: 20px;

      img {
        width: 100%;
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-about-us-section {
    width: 90%;
    margin: 80px auto;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;

    .left {
      .logo-heading {
        color: $color-yellow;
        font-size: $heading-2-font-size-lg;
      }
      .tagline-heading {
        font-size: $heading-1-font-size-lg;
        margin: 10px 0;
      }
      .about_listing_part {
        margin: 30px 20px;
      }
    }
    .right {
      padding: 20px;

      img {
        width: 100%;
      }
    }
  }
}
