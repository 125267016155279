@import "../../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-apv-portfolio {
    padding: 10px;
    text-align: center;
    margin: 20px auto;

    .apv-heading {
      font-size: $heading-1-font-size-mb;
      padding: 10px 0;
    }

    .apv-description {
      font-size: $heading-5-font-size-mb;
      padding: 10px 0;
    }

    .apv-portfolio-container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;

      overflow: hidden;
      margin: 20px 0;
      .video {
        video {
          width: 100%;
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-apv-portfolio {
    padding: 10px;
    text-align: center;
    margin: 20px auto;
    padding: 20px;

    .apv-heading {
      font-size: $heading-1-font-size-mb;
      padding: 10px 0;
    }

    .apv-description {
      font-size: $heading-5-font-size-mb;
      padding: 10px 0;
    }

    .apv-portfolio-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;

      overflow: hidden;
      margin: 20px 0;
      .video {
        video {
          width: 100%;
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-apv-portfolio {
    padding: 20px;
    text-align: center;
    margin: 40px auto;

    .apv-heading {
      font-size: $heading-1-font-size-lg;
      padding: 10px 0;
    }

    .apv-description {
      font-size: $heading-5-font-size-lg;
      padding: 10px 0;
    }

    .apv-portfolio-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;

      overflow: hidden;
      margin: 20px 0;
      .video {
        video {
          width: 100%;
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-apv-portfolio {
    padding: 20px;
    text-align: center;
    width: 90%;
    margin: 40px auto;

    .apv-heading {
      font-size: $heading-1-font-size-lg;
      padding: 10px 0;
    }

    .apv-description {
      font-size: $heading-5-font-size-lg;
      padding: 10px 0;
    }

    .apv-portfolio-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;

      overflow: hidden;
      margin: 20px 0;
      .video {
        video {
          width: 100%;
        }
      }
    }
  }
}
