@import "../../../ComponentStyles/components-styles.scss";

$PRheader-heading-size: 64px;
$PRheader-heading-size-md: 52px;
$PRheader-heading-size-sm: 40px;
$PRheader-heading-size-xs: 28px;

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c_photography_faqs {
    padding: 10px;
    margin: 40px auto;
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c_photography_faqs {
    padding: 10px;
    margin: 40px auto;
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c_photography_faqs {
    padding: 20px;
    width: 90%;
    margin: 80px auto;
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c_photography_faqs {
    padding: 20px;
    width: 90%;
    margin: 80px auto;
  }
}
