@import "../../../ComponentStyles/components-styles.scss";

$PRheader-heading-size: 64px;
$PRheader-heading-size-md: 52px;
$PRheader-heading-size-sm: 40px;
$PRheader-heading-size-xs: 28px;

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c_main_eligibility {
    padding: 20px;
    margin: 0 auto;

    .parent_about_eligibility {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      grid-row-gap: 0px;
      align-items: center;
      justify-content: center;

      .parent_image_eligibilty {
        .camera_image {
          width: 100%;
        }
      }

      .parent_about_eligibility_description {
        .camera_title {
          font-size: $heading-4-font-size-lg;
          font-weight: 800;
          padding: 0 0 20px 0;
          color: $color-yellow;
        }
        .camera_description {
          font-size: $heading-6-font-size-mb;
          font-weight: 400;
          padding: 0 0 30px 0;
          color: #000;
        }

        .parent_eligibility_head {
          h3 {
            font-size: $heading-4-font-size-lg;
            padding: 0 0 20px 0;
          }

          .ordered_criteria {
            padding: 30px 0 30px 20px;
          }
        }
        .parent_why_us {
          h3 {
            font-size: $heading-4-font-size-lg;
            padding: 0 0 20px 0;
          }
          .un_ordered_criteria {
            padding: 30px 0 30px 20px;
          }
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c_main_eligibility {
    padding: 20px;
    width: 90%;
    margin: 0 auto;

    .parent_about_eligibility {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      grid-row-gap: 0px;
      align-items: center;
      justify-content: center;

      .parent_image_eligibilty {
        .camera_image {
          width: 100%;
        }
      }

      .parent_about_eligibility_description {
        .camera_title {
          font-size: $PRheader-heading-size-xs;
          font-weight: 800;
          padding: 0 0 20px 0;
          color: $color-yellow;
        }
        .camera_description {
          font-size: $heading-6-font-size-mb;
          font-weight: 400;
          padding: 0 0 30px 0;
          color: #000;
        }

        .parent_eligibility_head {
          h3 {
            font-size: $heading-4-font-size-lg;
            padding: 0 0 20px 0;
          }

          .ordered_criteria {
            padding: 30px 0 30px 20px;
          }
        }
        .parent_why_us {
          h3 {
            font-size: $heading-4-font-size-lg;
            padding: 0 0 20px 0;
          }
          .un_ordered_criteria {
            padding: 30px 0 30px 20px;
          }
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c_main_eligibility {
    padding: 20px;
    width: 90%;
    margin: 0 auto;

    .parent_about_eligibility {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-row-gap: 0px;
      align-items: center;
      justify-content: center;

      .parent_image_eligibilty {
        .camera_image {
          width: 100%;
        }
      }

      .parent_about_eligibility_description {
        .camera_title {
          font-size: $heading-1-font-size-mb;
          font-weight: 800;
          padding: 0 0 20px 0;
          color: $color-yellow;
        }
        .camera_description {
          font-size: $heading-6-font-size-mb;
          font-weight: 400;
          padding: 0 0 30px 0;
          color: #000;
        }

        .parent_eligibility_head {
          h3 {
            font-size: $heading-4-font-size-lg;
            padding: 0 0 20px 0;
          }

          .ordered_criteria {
            padding: 30px 0 30px 20px;
          }
        }
        .parent_why_us {
          h3 {
            font-size: $heading-4-font-size-lg;
            padding: 0 0 20px 0;
          }
          .un_ordered_criteria {
            padding: 30px 0 30px 20px;
          }
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c_main_eligibility {
    padding: 20px;
    width: 90%;
    margin: 0 auto;

    .parent_about_eligibility {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-row-gap: 0px;
      align-items: center;
      justify-content: center;

      .parent_image_eligibilty {
        .camera_image {
          width: 100%;
        }
      }

      .parent_about_eligibility_description {
        .camera_title {
          font-size: $heading-1-font-size-mb;
          font-weight: 800;
          padding: 0 0 20px 0;
          color: $color-yellow;
        }
        .camera_description {
          font-size: $heading-6-font-size-mb;
          font-weight: 400;
          padding: 0 0 30px 0;
          color: #000;
        }

        .parent_eligibility_head {
          h3 {
            font-size: $heading-4-font-size-lg;
            padding: 0 0 20px 0;
          }

          .ordered_criteria {
            padding: 30px 0 30px 20px;
          }
        }
        .parent_why_us {
          h3 {
            font-size: $heading-4-font-size-lg;
            padding: 0 0 20px 0;
          }
          .un_ordered_criteria {
            padding: 30px 0 30px 20px;
          }
        }
      }
    }
  }
}
