@import "../../../ComponentStyles/components-styles.scss";

$PRheader-heading-size: 64px;
$PRheader-heading-size-md: 52px;
$PRheader-heading-size-sm: 40px;
$PRheader-heading-size-xs: 28px;

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c_why_important_ranking {
    display: flex;
    justify-content: center;
    margin: 50px 0;
    padding: 10px;

    .parent_why_important_sec {
      text-align: center;

      .title {
        font-size: $heading-2-font-size-lg;
        color: $color-yellow;
      }
      .description {
        font-size: $heading-5-font-size-lg;
        padding: 20px 0 30px 0;
        letter-spacing: 1px;
      }
      .video_part {
        margin: 20px 0;
        .videoBtn {
          padding: 12px;
          color: white;
          background-color: $color-yellow;
          border-radius: 5px;
          margin: 0 10px;
          cursor: pointer;
        }
      }
      .keywordRankPartImg {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
  .keywordRankPartSec {
    text-align: center;
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c_why_important_ranking {
    display: flex;
    justify-content: center;
    margin: 50px 0;
    padding: 10px;

    .parent_why_important_sec {
      text-align: center;

      .title {
        font-size: $PRheader-heading-size-md;
        color: $color-yellow;
      }
      .description {
        font-size: $heading-4-font-size-lg;
        padding: 20px 0 30px 0;
        letter-spacing: 1px;
      }
      .video_part {
        margin: 20px 0;
        .videoBtn {
          padding: 12px;
          color: white;
          background-color: $color-yellow;
          border-radius: 5px;
          margin: 0 10px;
          cursor: pointer;
        }
      }
      .keywordRankPartImg {
        width: 100%;
      }
    }
  }
  .keywordRankPartSec {
    text-align: center;
    img {
      width: 100%;
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c_why_important_ranking {
    display: flex;
    justify-content: center;
    margin: 50px 0;
    padding: 10px;

    .parent_why_important_sec {
      text-align: center;

      .title {
        font-size: $PRheader-heading-size-md;
        color: $color-yellow;
      }
      .description {
        font-size: $heading-4-font-size-lg;
        padding: 20px 0 30px 0;
        letter-spacing: 1px;
      }
      .video_part {
        margin: 20px 0;
        .videoBtn {
          padding: 12px;
          color: white;
          background-color: $color-yellow;
          border-radius: 5px;
          margin: 0 10px;
          cursor: pointer;
        }
      }
      .keywordRankPartImg {
        width: 70%;
      }
    }
  }
  .keywordRankPartSec {
    text-align: center;
    img {
      width: 100%;
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c_why_important_ranking {
    height: 500px;
    display: flex;
    justify-content: center;
    padding: 10px;

    .parent_why_important_sec {
      text-align: center;
      padding: 100px 200px;

      .title {
        font-size: $PRheader-heading-size-md;
        color: $color-yellow;
      }
      .description {
        font-size: $heading-4-font-size-lg;
        padding: 20px 0 30px 0;
        letter-spacing: 1px;
      }
      .video_part {
        margin: 20px 0;
        .videoBtn {
          padding: 12px;
          color: white;
          background-color: $color-yellow;
          border-radius: 5px;
          margin: 0 10px;
          cursor: pointer;
        }
      }
      .keywordRankPartImg {
        width: 40%;
      }
    }
  }
  .keywordRankPartSec {
    text-align: center;
    img {
      width: 100%;
    }
  }
}
