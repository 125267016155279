// for large screens

$text-font-size-lg: 18px;
$heading-1-font-size-lg: 42px;
$heading-2-font-size-lg: 36px;
$heading-3-font-size-lg: 30px;
$heading-4-font-size-lg: 24px;
$heading-5-font-size-lg: 18px;
$heading-6-font-size-lg: 12px;

// for mobile screens

$text-font-size-mb: 16px;
$heading-1-font-size-mb: 34px;
$heading-2-font-size-mb: 30px;
$heading-3-font-size-mb: 26px;
$heading-4-font-size-mb: 22px;
$heading-5-font-size-mb: 18px;
$heading-6-font-size-mb: 14px;

// colors
$color-yellow-light: #ff99009e;
$color-yellow: #93c01f;
$color-blue: #146eb4;
$color-black: #000000;
$color-gray: #232f3e;
$color-white: #ffffff;
$color-light-black: #333;
$color-light-gray: #555;
$color-light-green: #ebfbf7;
