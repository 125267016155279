@import "../../ComponentStyles/components-styles.scss";

$PRheader-heading-size: 64px;
$PRheader-heading-size-md: 52px;
$PRheader-heading-size-sm: 40px;
$PRheader-heading-size-xs: 28px;
/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c_packages_section {
    padding: 10px;
    margin: 80px auto;
    .parent_head_section {
      .header {
        text-align: center;
        #heading {
          font-size: $heading-1-font-size-mb;
        }
        .description {
          font-size: $text-font-size-lg;
        }
      }
    }
    .parent_packages_section {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      margin: 40px 0;
      .packages_card {
        .package_name {
          padding: 70px 10px;
          background-color: $color-yellow;
          display: flex;
          justify-content: center;
          text-align: center;
          align-items: center;
          border-radius: 10px 10px 0 0;
          .package_title {
            font-size: $heading-5-font-size-mb;
            letter-spacing: 3px;
          }
        }
        .package_lists {
          .parent_list {
            list-style-type: none;
            background-color: white;
            padding: 50px 10px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            cursor: pointer;
            line-height: 40px;
            min-height: 520px;
            border-radius: 0 0 10px 10px;

            svg {
              color: $color-yellow;
            }

            .list_data {
              padding-left: 15px;
            }
          }
          .get_a_quote {
            margin: 30px auto;
            text-align: center;
            .quote {
              background-color: $color-yellow;
              border-radius: 5px;
              border: none;
              outline: none;
              text-decoration: none;
              color: $color-black;
              letter-spacing: 3px;
              padding: 16px;
            }
          }
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c_packages_section {
    padding: 10px;
    margin: 80px auto;
    .parent_head_section {
      .header {
        text-align: center;
        #heading {
          font-size: $heading-1-font-size-mb;
        }
        .description {
          font-size: $text-font-size-lg;
        }
      }
    }
    .parent_packages_section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      margin: 40px 0;
      .packages_card {
        .package_name {
          padding: 70px 10px;
          background-color: $color-yellow;
          display: flex;
          justify-content: center;
          text-align: center;
          align-items: center;
          border-radius: 10px 10px 0 0;
          .package_title {
            font-size: $heading-5-font-size-mb;
            letter-spacing: 3px;
          }
        }
        .package_lists {
          .parent_list {
            list-style-type: none;
            background-color: white;
            padding: 50px 10px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            cursor: pointer;
            line-height: 40px;
            min-height: 520px;
            border-radius: 0 0 10px 10px;

            svg {
              color: $color-yellow;
            }

            .list_data {
              padding-left: 15px;
            }
          }
          .get_a_quote {
            margin: 30px auto;
            text-align: center;
            .quote {
              background-color: $color-yellow;
              border-radius: 5px;
              border: none;
              outline: none;
              text-decoration: none;
              color: $color-black;
              letter-spacing: 3px;
              padding: 16px;
            }
          }
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c_packages_section {
    padding: 20px;
    width: 90%;
    margin: 80px auto;
    .parent_head_section {
      .header {
        .hr {
          padding: 3px;
          background-color: $color-yellow;
          width: 100px;
        }
        #heading {
          font-size: $heading-1-font-size-lg;
        }
        .description {
          font-size: $heading-5-font-size-lg;
        }
      }
    }
    .parent_packages_section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      margin: 40px 0;
      .packages_card {
        .package_name {
          padding: 70px 10px;
          background-color: $color-yellow;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          border-radius: 10px 10px 0 0;
          .package_title {
            font-size: $heading-5-font-size-mb;
            letter-spacing: 3px;
          }
        }
        .package_lists {
          .parent_list {
            list-style-type: none;
            background-color: white;
            padding: 50px 10px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            cursor: pointer;
            line-height: 40px;
            min-height: 520px;
            border-radius: 0 0 10px 10px;

            svg {
              color: $color-yellow;
            }

            .list_data {
              padding-left: 15px;
            }
          }
          .get_a_quote {
            margin: 30px auto;
            text-align: center;
            .quote {
              background-color: $color-yellow;
              border-radius: 5px;
              border: none;
              outline: none;
              text-decoration: none;
              color: $color-black;
              letter-spacing: 3px;
              padding: 16px;
            }
          }
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c_packages_section {
    padding: 20px;
    width: 90%;
    margin: 80px auto;
    .parent_head_section {
      .header {
        .hr {
          padding: 3px;
          background-color: $color-yellow;
          width: 100px;
        }
        #heading {
          font-size: $heading-1-font-size-lg;
        }
        .description {
          font-size: $heading-5-font-size-lg;
        }
      }
    }
    .parent_packages_section {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 0px;
      margin: 40px 0;
      .packages_card {
        .package_name {
          padding: 70px 10px;
          background-color: $color-yellow;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          border-radius: 10px 10px 0 0;
          .package_title {
            font-size: $heading-5-font-size-mb;
            letter-spacing: 3px;
          }
        }
        .package_lists {
          .parent_list {
            list-style-type: none;
            background-color: white;
            padding: 50px 10px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            cursor: pointer;
            line-height: 40px;
            min-height: 520px;
            border-radius: 0 0 10px 10px;

            svg {
              color: $color-yellow;
            }

            .list_data {
              padding-left: 15px;
            }
          }
          .get_a_quote {
            margin: 30px auto;
            text-align: center;
            .quote {
              background-color: $color-yellow;
              border-radius: 5px;
              border: none;
              outline: none;
              text-decoration: none;
              color: $color-black;
              letter-spacing: 3px;
              padding: 16px;
            }
          }
        }
      }
    }
  }
}
