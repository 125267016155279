@import "../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-our-services {
    padding: 10px;
    margin: 80px auto;
    .head {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 0px;
      align-items: center;
      .left {
        .hr {
          display: none;
        }
        #our-services-heading {
          font-size: $heading-1-font-size-mb;
          text-align: center;
        }
      }
      .right {
        .our-services-content {
          text-align: center;
          font-size: $text-font-size-mb;
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-our-services {
    padding: 10px;
    margin: 80px auto;
    .head {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 0px;
      align-items: center;
      .left {
        .hr {
          display: none;
        }
        #our-services-heading {
          font-size: $heading-1-font-size-mb;
          text-align: center;
        }
      }
      .right {
        .our-services-content {
          text-align: center;
          font-size: $text-font-size-mb;
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-our-services {
    padding: 20px;
    margin: 80px auto;
    .head {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 0px;
      align-items: center;
      .left {
        .hr {
          padding: 3px;
          background-color: $color-yellow;
          width: 100px;
        }
        #our-services-heading {
          font-size: $heading-1-font-size-lg;
        }
      }
      .right {
        .our-services-content {
          font-size: $text-font-size-lg;
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-our-services {
    padding: 20px;
    width: 90%;
    margin: 80px auto;
    .head {
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 0px;
      align-items: center;
      .left {
        .hr {
          padding: 3px;
          background-color: $color-yellow;
          width: 100px;
        }
        #our-services-heading {
          font-size: $heading-1-font-size-lg;
        }
      }
      .right {
        .our-services-content {
          font-size: $text-font-size-lg;
        }
      }
    }
  }
}
