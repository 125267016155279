@import "../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-navbar {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-gray;
    .logo-container {
      #logo {
        font-size: $heading-4-font-size-mb;
        color: $color-gray;
      }
    }
    .nav {
      .nav-links {
        display: none;
      }
    }
    .nav-mobile {
      .nav-icon {
        font-size: $heading-4-font-size-lg;
      }
      .overlay {
        /* Height & width depends on how you want to reveal the overlay (see JS below) */
        height: 100%;
        width: 0;
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        left: 0;
        top: 0;
        background-color: $color-gray; /* Black fallback color */
        overflow-x: hidden; /* Disable horizontal scroll */
        transition: 0.3s; /* 0.3 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */

        .close-btn {
          color: $color-yellow;
          font-size: $heading-1-font-size-lg;
          text-decoration: none;
          padding: 20px;
          text-align: right;
          position: absolute;
          right: 20px;
        }

        .overlay-content {
          width: 100%; /* 100% width */
          text-align: left; /* Centered text/links */
          margin-top: 80px;
          .nav-links {
            display: block;
            list-style: none;
            .nav-item {
              padding: 20px;
              .nav-link {
                text-decoration: none;
                color: $color-yellow;
                font-size: $heading-5-font-size-mb;
              }
              .dropdown {
                position: relative;
                .dropdown-icon {
                  position: relative;
                  top: 3px;
                  left: 5px;
                }
                .nav-link {
                  background-color: inherit;
                  border: none;
                  padding: 0;
                }
                .dropdown-content {
                  min-width: 160px;
                  margin-top: 5px;
                  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                  z-index: 100;
                  transition: 0.3s;
                  overflow: hidden;
                  padding: 10px;
                  transform: scaleY(0);
                  transform-origin: top;
                  transition: transform 0.26s ease;
                  position: absolute;
                  a {
                    color: $color-yellow;
                    padding: 10px;
                    text-decoration: none;
                    display: block;
                    text-align: left;
                    &:hover {
                      background-color: $color-gray;
                      color: $color-white;
                    }
                  }
                }

                &:hover {
                  .dropdown-content {
                    margin-left: 5px;
                    transform: scaleY(1);
                    position: relative;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-navbar {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-gray;
    .logo-container {
      #logo {
        font-size: $heading-4-font-size-lg;
        color: $color-gray;
      }
    }
    .nav {
      .nav-links {
        display: none;
      }
    }
    .nav-mobile {
      .nav-icon {
        font-size: $heading-4-font-size-lg;
      }
      .overlay {
        /* Height & width depends on how you want to reveal the overlay (see JS below) */
        height: 100%;
        width: 0;
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        left: 0;
        top: 0;
        background-color: $color-gray; /* Black fallback color */
        overflow-x: hidden; /* Disable horizontal scroll */
        transition: 0.3s; /* 0.3 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */

        .close-btn {
          color: $color-yellow;
          font-size: $heading-1-font-size-lg;
          text-decoration: none;
          padding: 20px;
          text-align: right;
          position: absolute;
          right: 20px;
        }

        .overlay-content {
          width: 100%; /* 100% width */
          text-align: left; /* Centered text/links */
          margin-top: 80px;
          .nav-links {
            display: block;
            list-style: none;
            .nav-item {
              padding: 20px;
              .nav-link {
                text-decoration: none;
                color: $color-yellow;
                font-size: $heading-5-font-size-mb;
              }
              .dropdown {
                position: relative;
                .nav-link {
                  background-color: inherit;
                  border: none;
                }
                .dropdown-content {
                  min-width: 160px;
                  margin-top: 5px;
                  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                  z-index: 100;
                  transition: 0.3s;
                  overflow: hidden;
                  padding: 10px;
                  transform: scaleY(0);
                  transform-origin: top;
                  transition: transform 0.26s ease;
                  position: absolute;
                  a {
                    color: $color-yellow;
                    padding: 10px;
                    text-decoration: none;
                    display: block;
                    text-align: left;
                    &:hover {
                      background-color: $color-gray;
                      color: $color-white;
                    }
                  }
                }

                &:hover {
                  .dropdown-content {
                    margin-left: 5px;
                    transform: scaleY(1);
                    position: relative;
                  }
                }
                .dropdown-icon {
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-navbar {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-gray;
    .logo-container {
      #logo {
        font-size: $heading-4-font-size-lg;
        color: $color-gray;
      }
    }
    .nav-mobile {
      display: none;
    }
    .nav {
      .nav-mobile {
        display: none;
      }
      .nav-links {
        display: flex;
        list-style: none;
        align-items: center;
        .nav-item {
          .nav-link {
            font-size: $text-font-size-lg;
            cursor: pointer;
            padding: 10px 12px;
            font-weight: 500;
            margin: 0 5px;
            border: 1px solid $color-white;
            text-decoration: none;
            color: $color-black;

            &:hover {
              border: 1px solid $color-gray;
            }
          }
          .active {
            border: 1px solid $color-gray;
          }
          .dropdown {
            .nav-link {
              background-color: inherit;
              border: 1px solid $color-white;

              &:hover {
                border: 1px solid $color-gray;
              }
            }
            .dropdown-content {
              display: none;
              position: absolute;
              background-color: $color-white;
              min-width: 160px;
              box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
              z-index: 1;
              a {
                float: none;
                color: $color-gray;
                padding: 10px 12px;
                text-decoration: none;
                display: block;
                text-align: left;

                &:hover {
                  background-color: $color-gray;
                  color: $color-white;
                }
              }
            }

            &:hover {
              .dropdown-content {
                display: block;
              }
            }
            .dropdown-icon {
              vertical-align: middle;
            }
          }
          .cta-button {
            background-color: $color-yellow;
            font-weight: 700;
            text-transform: uppercase;
            color: $color-gray;
            text-decoration: none;
          }
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-navbar {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-gray;
    .logo-container {
      #logo {
        font-size: $heading-4-font-size-lg;
        color: $color-gray;
      }
    }
    .nav-mobile {
      display: none;
    }
    .nav {
      .nav-mobile {
        display: none;
      }
      .nav-links {
        display: flex;
        list-style: none;
        align-items: center;
        .nav-item {
          .nav-link {
            font-size: $text-font-size-lg;
            cursor: pointer;
            padding: 10px 12px;
            font-weight: 500;
            margin: 0 5px;
            border: 1px solid $color-white;
            text-decoration: none;
            color: $color-black;
            &:hover {
              border: 1px solid $color-gray;
            }
          }
          .active {
            border: 1px solid $color-gray;
          }
          .dropdown {
            .nav-link {
              background-color: inherit;
              border: 1px solid $color-white;

              &:hover {
                border: 1px solid $color-gray;
              }
            }
            .dropdown-content {
              display: none;
              position: absolute;
              background-color: $color-white;
              min-width: 160px;
              box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
              z-index: 1;
              a {
                float: none;
                color: $color-gray;
                padding: 10px 12px;
                text-decoration: none;
                display: block;
                text-align: left;

                &:hover {
                  background-color: $color-gray;
                  color: $color-white;
                }
              }
            }

            &:hover {
              .dropdown-content {
                display: block;
              }
            }
            .dropdown-icon {
              vertical-align: middle;
            }
          }
          .cta-button {
            background-color: $color-yellow;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            color: $color-gray;
          }
        }
      }
    }
  }
}
