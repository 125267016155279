@import "../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-about-us-header {
    height: 300px;
    width: 100%;
    background-image: url("../../../static/aboutusImg.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.2));
    background-blend-mode: overlay;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .heading {
      font-size: $heading-1-font-size-mb;
      color: $color-white;
      text-align: center;
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-about-us-header {
    height: 400px;
    width: 100%;
    background-image: url("../../../static/aboutusImg.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.2));
    background-blend-mode: overlay;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .heading {
      font-size: calc($heading-1-font-size-lg * 1.3);
      color: $color-white;
      text-align: center;
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-about-us-header {
    height: 400px;
    width: 100%;
    background-image: url("../../../static/aboutusImg.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.2));
    background-blend-mode: overlay;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .heading {
      font-size: calc($heading-1-font-size-lg * 1.3);
      color: $color-white;
      text-align: center;
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-about-us-header {
    height: 600px;
    width: 100%;
    background-image: url("../../../static/aboutusImg.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.2));
    background-blend-mode: overlay;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .heading {
      font-size: calc($heading-1-font-size-lg * 2);
      color: $color-white;
      text-align: center;
    }
  }
}
