@import "../../ComponentStyles/components-styles.scss";
// media query template
/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-parentImageSection {
    text-align: center;
    img {
      width: 100%;
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-parentImageSection {
    text-align: center;
    img {
      width: 100%;
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-parentImageSection {
    text-align: center;
    img {
      width: 100%;
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-parentImageSection {
    text-align: center;
    img {
      width: 100%;
    }
  }
}
