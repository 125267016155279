@import "../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-how-we-work {
    padding: 10px;
    margin: 40px auto;

    .we-work-wrapper {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 40px;

      .we-work-card {
        text-align: center;
        .icon {
          font-size: calc($heading-1-font-size-lg * 1.4);
          color: $color-yellow;
        }
        .title {
          font-size: $heading-6-font-size-mb;
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-how-we-work {
    padding: 20px;
    margin: 80px auto;

    .we-work-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 0px;

      .we-work-card {
        text-align: center;
        .icon {
          font-size: calc($heading-1-font-size-lg * 1.4);
          color: $color-yellow;
        }
        .title {
          font-size: $heading-6-font-size-mb;
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-how-we-work {
    padding: 20px;
    margin: 80px auto;

    .we-work-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 0px;

      .we-work-card {
        text-align: center;
        .icon {
          font-size: calc($heading-1-font-size-lg * 2);
          color: $color-yellow;
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-how-we-work {
    padding: 20px;
    width: 90%;
    margin: 80px auto;

    .we-work-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 0px;

      .we-work-card {
        text-align: center;
        .icon {
          font-size: calc($heading-1-font-size-lg * 2);
          color: $color-yellow;
        }
      }
    }
  }
}
