@import "../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-founder-statement {
    padding: 10px;
    background-color: $color-yellow;
    .inner-container {
      margin: 40px auto;
      text-align: center;

      #heading {
        font-size: $heading-1-font-size-mb;
      }

      .statement-container {
        .statement {
          font-size: $heading-5-font-size-mb;
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-founder-statement {
    padding: 20px;
    background-color: $color-yellow;
    .inner-container {
      margin: 80px auto;
      text-align: center;

      #heading {
        font-size: $heading-1-font-size-mb;
      }

      .statement-container {
        .statement {
          font-size: $text-font-size-mb;
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-founder-statement {
    padding: 20px;
    background-color: $color-yellow;
    .inner-container {
      margin: 80px auto;
      text-align: center;

      #heading {
        font-size: $heading-1-font-size-lg;
      }

      .statement-container {
        .icon {
          font-size: $heading-1-font-size-lg;
          margin-right: 15px;
        }
        .statement {
          font-size: $heading-5-font-size-lg;
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-founder-statement {
    padding: 20px;
    background-color: $color-yellow;
    .inner-container {
      width: 90%;
      margin: 80px auto;
      text-align: center;

      #heading {
        font-size: $heading-1-font-size-lg;
      }

      .statement-container {
        .icon {
          font-size: $heading-1-font-size-lg;
          margin-right: 15px;
        }
        .statement {
          font-size: $heading-5-font-size-lg;
        }
      }
    }
  }
}
