@import "../../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-our-services-section {
    margin-top: 40px;
    .container {
      .parent-box {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 45px;
        justify-items: center;
        .grid-item {
          border: 1px solid $color-gray;
          width: 100%;
          padding: 80px 20px;
          position: relative;
          cursor: pointer;

          &:hover h2 {
            opacity: 1;
            transform: translateY(-90px);
            color: $color-white;
          }
          &:hover h3 {
            opacity: 1;
            color: $color-white;
          }
          &:hover p {
            opacity: 1;
            color: $color-white;
          }

          h2 {
            position: absolute;
            top: 100px;
            font-weight: 800;
            font-size: 4rem;
            z-index: 1;
            opacity: 0.1;
            transition: 0.5s;
          }
          h3 {
            position: relative;
            font-size: 1.5em;
            z-index: 2;
            color: $color-light-black;
            transition: 0.5s;
            margin: 15px 0;
          }
          p {
            position: relative;
            z-index: 2;
            color: $color-light-gray;
            transition: 0.5s;
          }
        }
        .grid-item::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $color-yellow;
          transform: scaleY(0);
          transform-origin: top;
          transition: transform 0.5s;
        }
        .grid-item:hover::before {
          transform: scaleY(1);
          transform-origin: bottom;
          transition: transform 0.5s;
        }
      }

      .head-section {
        text-align: center;
        padding: 10px 0 40px 0;
        h2 {
          color: $color-yellow;
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-our-services-section {
    margin-top: 40px;
    .container {
      .parent-box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 45px;
        justify-items: center;
        .grid-item {
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          width: 100%;
          padding: 80px 20px;
          cursor: pointer;

          position: relative;

          &:hover h2 {
            opacity: 1;
            transform: translateY(-90px);
            color: $color-white;
          }
          &:hover h3 {
            opacity: 1;
            color: $color-white;
          }
          &:hover p {
            opacity: 1;
            color: $color-white;
          }

          h2 {
            position: absolute;
            top: 100px;
            font-weight: 800;
            font-size: 4rem;
            z-index: 1;
            opacity: 0.1;
            transition: 0.5s;
          }
          h3 {
            position: relative;
            font-size: 1.5em;
            z-index: 2;
            color: $color-light-black;
            transition: 0.5s;
            margin: 15px 0;
          }
          p {
            position: relative;
            z-index: 2;
            color: $color-light-gray;
            transition: 0.5s;
          }
        }
        .grid-item::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $color-yellow;
          transform: scaleY(0);
          transform-origin: top;
          transition: transform 0.5s;
        }
        .grid-item:hover::before {
          transform: scaleY(1);
          transform-origin: bottom;
          transition: transform 0.5s;
        }
      }

      .head-section {
        text-align: center;
        padding: 10px 0 40px 0;
        h2 {
          color: $color-yellow;
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-our-services-section {
    margin-top: 40px;
    .container {
      .parent-box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        justify-items: center;
        grid-column-gap: 30px;
        grid-row-gap: 45px;
        .grid-item {
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          width: 100%;
          padding: 80px 20px;
          position: relative;
          cursor: pointer;

          &:hover h2 {
            opacity: 1;
            transform: translateY(-90px);
            color: $color-white;
          }
          &:hover h3 {
            opacity: 1;
            color: $color-white;
          }
          &:hover p {
            opacity: 1;
            color: $color-white;
          }

          h2 {
            position: absolute;
            top: 100px;
            font-weight: 800;
            font-size: 4rem;
            z-index: 1;
            opacity: 0.1;
            transition: 0.5s;
          }
          h3 {
            position: relative;
            font-size: 1.5em;
            z-index: 2;
            color: $color-light-black;
            transition: 0.5s;
            margin: 15px 0;
          }
          p {
            position: relative;
            z-index: 2;
            color: $color-light-gray;
            transition: 0.5s;
          }
        }
        .grid-item::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $color-yellow;
          transform: scaleY(0);
          transform-origin: top;
          transition: transform 0.5s;
        }
        .grid-item:hover::before {
          transform: scaleY(1);
          transform-origin: bottom;
          transition: transform 0.5s;
        }
      }

      .head-section {
        text-align: center;
        padding: 10px 0 40px 0;
        h2 {
          color: $color-yellow;
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-our-services-section {
    margin-top: 40px;
    .container {
      .parent-box {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 30px;
        justify-items: center;
        grid-row-gap: 45px;
        .grid-item {
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          width: 100%;
          padding: 80px 20px;
          cursor: pointer;
          position: relative;

          &:hover h2 {
            opacity: 1;
            transform: translateY(-90px);
            color: $color-white;
          }
          &:hover h3 {
            opacity: 1;
            color: $color-white;
          }
          &:hover p {
            opacity: 1;
            color: $color-white;
          }

          h2 {
            position: absolute;
            top: 100px;
            font-weight: 800;
            font-size: 4rem;
            z-index: 1;
            opacity: 0.1;
            transition: 0.5s;
          }
          h3 {
            position: relative;
            font-size: 1.5em;
            z-index: 2;
            color: $color-light-black;
            transition: 0.5s;
            margin: 15px 0;
          }
          p {
            position: relative;
            z-index: 2;
            color: $color-light-gray;
            transition: 0.5s;
          }
        }
        .grid-item::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $color-yellow;
          transform: scaleY(0);
          transform-origin: top;
          transition: transform 0.5s;
        }
        .grid-item:hover::before {
          transform: scaleY(1);
          transform-origin: bottom;
          transition: transform 0.5s;
        }
      }

      .head-section {
        text-align: center;
        padding: 10px 0 40px 0;
        h2 {
          color: $color-yellow;
        }
      }
    }
  }
}
