@import "../../../ComponentStyles/components-styles.scss";

$PRheader-heading-size: 64px;
$PRheader-heading-size-md: 52px;
$PRheader-heading-size-sm: 40px;
$PRheader-heading-size-xs: 28px;

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c_product_photo_header {
    // background-image: url("../../../../static/header-2.jpg"), linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9));
    background-color: black;
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    padding: 10px;

    .parent_header_photography {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 50px;
      margin: 20px auto;
      align-items: center;
      justify-content: center;

      .description_section {
        color: white;
        .product_photo_heading {
          font-size: $heading-3-font-size-lg;
          padding: 30px 0;
        }
        .tagline {
          font-size: $heading-5-font-size-lg;
          padding: 0 0 30px 0;
        }
        .photograogy_list {
          list-style-type: none;
          line-height: 30px;
          svg {
            color: $color-yellow;
          }
          span {
            padding-left: 15px;
          }
        }
        .photo_header_btn {
          margin: 45px 0 0 0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          flex-direction: row;
          row-gap: 30px;
          column-gap: 5px;
          #portfolio {
            background-color: $color-yellow;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            color: #232f3e;
            padding: 10px 20px;
            border-radius: 4px;
          }
          #ourPackages {
            background-color: $color-yellow;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            color: #232f3e;
            padding: 10px 20px;
            border-radius: 4px;
          }
        }
      }
      .image_section {
        .header_photo_img {
          width: 100%;
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c_product_photo_header {
    // background-image: url("../../../../static/header-2.jpg"), linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9));
    background-color: black;
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    padding: 10px;

    .parent_header_photography {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 50px;
      margin: 20px auto;
      align-items: center;
      justify-content: center;

      .description_section {
        color: white;
        .product_photo_heading {
          font-size: $PRheader-heading-size-sm;
          padding: 30px 0;
        }
        .tagline {
          font-size: $heading-5-font-size-lg;
          padding: 0 0 30px 0;
        }
        .photograogy_list {
          list-style-type: none;
          line-height: 30px;
          svg {
            color: $color-yellow;
          }
          span {
            padding-left: 15px;
          }
        }
        .photo_header_btn {
          margin: 45px 0 0 0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          flex-direction: row;
          row-gap: 30px;
          #portfolio {
            background-color: $color-yellow;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            color: #232f3e;
            padding: 10px 20px;
            margin: 0 10px 0 0;
            border-radius: 4px;
          }
          #ourPackages {
            background-color: $color-yellow;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            color: #232f3e;
            padding: 10px 20px;
            border-radius: 4px;
          }
        }
      }
      .image_section {
        .header_photo_img {
          width: 100%;
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c_product_photo_header {
    // background-image: url("../../../../static/header-2.jpg"), linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9));
    background-color: black;
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    padding: 20px;

    .parent_header_photography {
      width: 90%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 50px;
      grid-row-gap: 0px;
      margin: 80px auto;
      align-items: center;
      justify-content: center;

      .description_section {
        color: white;
        .product_photo_heading {
          font-size: $PRheader-heading-size-sm;
          padding: 30px 0;
        }
        .tagline {
          font-size: $heading-5-font-size-lg;
          padding: 0 0 30px 0;
        }
        .photograogy_list {
          list-style-type: none;
          line-height: 30px;
          svg {
            color: $color-yellow;
          }
          span {
            padding-left: 15px;
          }
        }
        .photo_header_btn {
          margin: 45px 0 0 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          flex-direction: row;
          row-gap: 30px;
          #portfolio {
            background-color: $color-yellow;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            color: #232f3e;
            padding: 10px 20px;
            margin: 0 10px 0 0;
            border-radius: 4px;
          }
          #ourPackages {
            background-color: $color-yellow;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            color: #232f3e;
            padding: 10px 20px;
            border-radius: 4px;
          }
        }
      }
      .image_section {
        .header_photo_img {
          width: 100%;
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c_product_photo_header {
    height: 800px;
    // background-image: url("../../../../static/header-2.jpg"), linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9));
    background-color: black;
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    padding: 20px;

    .parent_header_photography {
      width: 90%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 50px;
      grid-row-gap: 0px;
      margin: 80px auto;
      align-items: center;
      justify-content: center;

      .description_section {
        color: white;
        .product_photo_heading {
          font-size: $PRheader-heading-size-md;
          padding: 30px 0;
        }
        .tagline {
          font-size: $heading-4-font-size-lg;
          padding: 0 0 30px 0;
        }
        .photograogy_list {
          list-style-type: none;
          line-height: 30px;
          svg {
            color: $color-yellow;
          }
          span {
            padding-left: 15px;
          }
        }
        .photo_header_btn {
          margin: 45px 0 0 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          flex-direction: row;
          #portfolio {
            background-color: $color-yellow;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            color: #232f3e;
            padding: 10px 20px;
            margin: 0 10px 0 0;
            border-radius: 4px;
          }
          #ourPackages {
            background-color: $color-yellow;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            color: #232f3e;
            padding: 10px 20px;
            border-radius: 4px;
          }
        }
      }
      .image_section {
        .header_photo_img {
          width: 100%;
        }
      }
    }
  }
}
