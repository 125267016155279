@import "../../../ComponentStyles/components-styles.scss";

$PRheader-heading-size: 64px;
$PRheader-heading-size-md: 52px;
$PRheader-heading-size-sm: 40px;
$PRheader-heading-size-xs: 28px;

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c_A_Header_section {
    background-image: url("../../../../static/EBC-banner.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    padding-top: 75px;
    .parent_header_section {
      .title {
        color: $color-white;
        font-size: $heading-2-font-size-mb;
        text-align: center;
        font-size: 24px;
      }

      .a_content_listing {
        padding: 30px 20px;

        .photograogy_list {
          list-style-type: none;
          line-height: 30px;
          svg {
            color: $color-yellow;
          }
          span {
            padding-left: 15px;
            color: $color-white;
          }
        }
      }
      .tagline_a_content {
        text-align: center;
        color: $color-white;
      }

      .content_header_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;
        margin: 20px 0;
        #get_a_quote {
          background-color: $color-yellow;
          font-weight: 700;
          text-transform: uppercase;
          text-decoration: none;
          color: #232f3e;
          padding: 10px 20px;
          margin: 0 10px 10px 0;
          border-radius: 4px;
          cursor: pointer;
        }
        #schedule {
          background-color: $color-yellow;
          font-weight: 700;
          text-transform: uppercase;
          text-decoration: none;
          color: #232f3e;
          padding: 10px 20px;
          margin: 0 0 10px 0;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c_A_Header_section {
    background-image: url("../../../../static/EBC-banner.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    padding-top: 75px;
    .parent_header_section {
      .title {
        color: $color-white;
        font-size: $heading-2-font-size-mb;
      }

      .a_content_listing {
        width: 55%;
        padding: 30px 0;
        margin: 0 auto;
        .photograogy_list {
          list-style-type: none;
          line-height: 30px;
          svg {
            color: $color-yellow;
          }
          span {
            padding-left: 15px;
            color: $color-white;
          }
        }
      }
      .tagline_a_content {
        text-align: center;
        color: $color-white;
      }

      .content_header_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;
        margin: 20px 0;
        #get_a_quote {
          background-color: $color-yellow;
          font-weight: 700;
          text-transform: uppercase;
          text-decoration: none;
          color: #232f3e;
          padding: 10px 20px;
          margin: 0 10px 0 0;
          border-radius: 4px;
          cursor: pointer;
        }
        #schedule {
          background-color: $color-yellow;
          font-weight: 700;
          text-transform: uppercase;
          text-decoration: none;
          color: #232f3e;
          padding: 10px 20px;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c_A_Header_section {
    background-image: url("../../../../static/EBC-banner.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    padding-top: 75px;
    .parent_header_section {
      .title {
        color: $color-white;
        font-size: $heading-2-font-size-mb;
      }

      .a_content_listing {
        width: 55%;
        padding: 30px 0;
        margin: 0 auto;
        .photograogy_list {
          list-style-type: none;
          line-height: 30px;
          svg {
            color: $color-yellow;
          }
          span {
            padding-left: 15px;
            color: $color-white;
          }
        }
      }
      .tagline_a_content {
        text-align: center;
        color: $color-white;
      }

      .content_header_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;
        margin: 20px 0;
        #get_a_quote {
          background-color: $color-yellow;
          font-weight: 700;
          text-transform: uppercase;
          text-decoration: none;
          color: #232f3e;
          padding: 10px 20px;
          margin: 0 10px 0 0;
          border-radius: 4px;
          cursor: pointer;
        }
        #schedule {
          background-color: $color-yellow;
          font-weight: 700;
          text-transform: uppercase;
          text-decoration: none;
          color: #232f3e;
          padding: 10px 20px;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c_A_Header_section {
    background-image: url("../../../../static/EBC-banner.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    padding-top: 75px;
    .parent_header_section {
      .title {
        color: $color-white;
        font-size: $PRheader-heading-size-md;
      }

      .a_content_listing {
        width: 30%;
        padding: 30px 0;
        margin: 0 auto;
        .photograogy_list {
          list-style-type: none;
          line-height: 30px;
          svg {
            color: $color-yellow;
          }
          span {
            padding-left: 15px;
            color: $color-white;
          }
        }
      }
      .tagline_a_content {
        text-align: center;
        color: $color-white;
        padding: 20px 0;
      }

      .content_header_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;
        margin: 40px 0;
        #get_a_quote {
          background-color: $color-yellow;
          font-weight: 700;
          text-transform: uppercase;
          text-decoration: none;
          color: #232f3e;
          padding: 10px 20px;
          margin: 0 10px 0 0;
          border-radius: 4px;
          cursor: pointer;
        }
        #schedule {
          background-color: $color-yellow;
          font-weight: 700;
          text-transform: uppercase;
          text-decoration: none;
          color: #232f3e;
          padding: 10px 20px;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
}
