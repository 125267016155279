@import "../../ComponentStyles/components-styles.scss";

.slick-slide img {
  max-width: 100%;
}

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c_slider {
    width: 90%;
    margin: 40px auto;
    .top {
      padding-bottom: 30px;

      .hr {
        padding: 3px;
        background-color: $color-yellow;
        width: 100px;
        display: none;
      }
      .section-title {
        font-size: $heading-1-font-size-mb;
        text-align: center;
      }
    }
    .testimonal_box {
      border: 1px solid $color-yellow;
      border-radius: 10px;
      padding: 20px;
      .quoteImg svg {
        font-size: 40px;
        color: $color-yellow;
      }
      .testimonal_quote {
        font-style: italic;
        font-size: $heading-6-font-size-lg;
      }
      .details {
        padding: 20px 0;
        .ImgBox {
          .testimonal_image svg {
            font-size: 40px;
            color: $color-yellow;
          }
        }
        .testi_name {
          font-size: 14px;
          font-style: italic;
        }
        .testimonal_positon {
          font-size: 14px;
          font-style: italic;
        }
      }
    }
  }
  .slide .slider img {
    width: 100%;
    height: 450px;
  }
  .slide {
    transform: scale(0);
    transition: 0.5s;
    filter: blur(5px);
    &:focus {
      border: none;
      outline: none;
    }
    .test_image {
      width: 100%;
    }
  }
  .slide-active {
    filter: blur(0);
    opacity: 1;
    transform: scale(1);
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c_slider {
    margin: 40px auto;
    .top {
      padding-bottom: 30px;

      .hr {
        padding: 3px;
        background-color: $color-yellow;
        width: 100px;
        display: none;
      }
      .section-title {
        font-size: $heading-1-font-size-mb;
        text-align: center;
      }
    }
    .testimonal_box {
      border: 1px solid $color-yellow;
      border-radius: 10px;
      padding: 20px;
      .quoteImg svg {
        font-size: 40px;
        color: $color-yellow;
      }
      .testimonal_quote {
        font-style: italic;
        font-size: $heading-6-font-size-lg;
      }
      .details {
        padding: 20px 0;
        .ImgBox {
          .testimonal_image svg {
            font-size: 40px;
            color: $color-yellow;
          }
        }
        .testi_name {
          font-size: 14px;
          font-style: italic;
        }
        .testimonal_positon {
          font-size: 14px;
          font-style: italic;
        }
      }
    }
  }
  .slide .slider img {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
  .slide {
    transform: scale(0.7);
    transition: 0.5s;
    filter: blur(5px);
    &:focus {
      border: none;
      outline: none;
    }
  }
  .slide-active {
    filter: blur(0);
    opacity: 1;
    transform: scale(1);
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c_slider {
    padding: 0px 20px;
    margin: 0 auto 80px;
    .top {
      padding: 0 0 50px 0;
      .hr {
        padding: 3px;
        background-color: $color-yellow;
        width: 100px;
      }
      .section-title {
        font-size: $heading-1-font-size-lg;
      }
    }
    .testimonal_box {
      border: 1px solid $color-yellow;
      border-radius: 10px;
      padding: 20px;
      .quoteImg svg {
        font-size: 40px;
        color: $color-yellow;
      }
      .testimonal_quote {
        font-style: italic;
      }
      .details {
        padding: 20px 0;
        .ImgBox {
          .testimonal_image svg {
            font-size: 40px;
            color: $color-yellow;
          }
        }
        .testi_name {
          font-size: 14px;
          font-style: italic;
        }
        .testimonal_positon {
          font-size: 14px;
          font-style: italic;
        }
      }
    }
  }
  .slide .slider img {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
  .slide {
    transform: scale(0.7);
    transition: 0.5s;
    filter: blur(5px);
    overflow: hidden;
    &:focus {
      border: none;
      outline: none;
    }
  }
  .slide-active {
    filter: blur(0);
    opacity: 1;
    transform: scale(1);
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c_slider {
    width: 90%;
    margin: 40px auto;
    padding: 20px;

    .top {
      .hr {
        padding: 3px;
        background-color: $color-yellow;
        width: 100px;
      }
      .section-title {
        font-size: $heading-1-font-size-lg;
      }
    }
    .testimonal_box {
      border: 1px solid $color-yellow;
      border-radius: 10px;
      padding: 20px;
      .quoteImg svg {
        font-size: 40px;
        color: $color-yellow;
      }
      .testimonal_quote {
        font-style: italic;
      }
      .details {
        padding: 20px 0;
        .ImgBox {
          .testimonal_image svg {
            font-size: 40px;
            color: $color-yellow;
          }
        }
        .testi_name {
          font-size: 14px;
          font-style: italic;
        }
        .testimonal_positon {
          font-size: 14px;
          font-style: italic;
        }
      }
    }
  }
  .slide .slider img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
  .slide {
    transform: scale(0.85);
    transition: 0.5s;
    overflow: hidden;
    border-radius: 10px;
    filter: blur(5px);
    &:focus {
      border: none;
      outline: none;
    }
  }
  .slide-active {
    filter: blur(0);
    opacity: 1;
    transform: scale(1);
    z-index: 100;
  }
}
