@import "../../../ComponentStyles/components-styles.scss";

$PRheader-heading-size: 64px;
$PRheader-heading-size-md: 52px;
$PRheader-heading-size-sm: 40px;
$PRheader-heading-size-xs: 28px;

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c_product_ranking {
    background-image: url("../../../../static/header-2.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    .ranking_details {
      text-align: center;
      color: $color-yellow;
      .amazon {
        background-color: $color-yellow;
        color: $color-black;
      }
      .ranking_title {
        font-size: $heading-2-font-size-lg;
        padding: 40px 0;
      }
      .ranking_tagline {
        font-size: $heading-3-font-size-mb;
        padding: 0 0 20px 0;
      }
      .ranking_question {
        font-size: $heading-4-font-size-mb;
        padding: 20px 0;
      }
      .ranking_advantages {
        font-size: $heading-4-font-size-mb;
        padding: 20px 0;
      }

      .ranking_benefits {
        padding: 20px 0;
        color: $color-white;
      }
      .consultancy_tagline {
        font-size: $heading-5-font-size-lg;
      }

      .btnpart {
        padding: 50px 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .get_a_quote {
        padding: 10px;
        color: white;
        background-color: $color-yellow;
        border-radius: 5px;
        margin: 0 10px;
        text-decoration: none;
      }
      .book_a_call {
        padding: 10px;
        color: white;
        background-color: $color-yellow;
        border-radius: 5px;
        margin: 0 10px;
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c_product_ranking {
    height: 800px;
    background-image: url("../../../../static/header-2.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    .ranking_details {
      text-align: center;
      color: $color-yellow;
      .amazon {
        background-color: $color-yellow;
        color: $color-black;
      }
      .ranking_title {
        font-size: $PRheader-heading-size-sm;
      }
      .ranking_tagline {
        font-size: $heading-3-font-size-lg;
      }
      .ranking_question {
        font-size: $heading-4-font-size-mb;
      }
      .ranking_advantages {
        font-size: $heading-4-font-size-mb;
      }

      .ranking_benefits {
        padding: 20px 0;
        color: $color-white;
      }
      .consultancy_tagline {
        font-size: $heading-5-font-size-lg;
      }

      .btnpart {
        padding: 50px 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .get_a_quote {
        padding: 10px 20px;
        color: white;
        background-color: $color-yellow;
        border-radius: 5px;
        margin: 0 10px;
        text-decoration: none;
      }
      .book_a_call {
        padding: 10px 20px;
        color: white;
        background-color: $color-yellow;
        border-radius: 5px;
        margin: 0 10px;
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c_product_ranking {
    height: 800px;
    background-image: url("../../../../static/header-2.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    .ranking_details {
      text-align: center;
      color: $color-yellow;
      .amazon {
        background-color: $color-yellow;
        color: $color-black;
      }
      .ranking_title {
        font-size: $PRheader-heading-size-md;
      }
      .ranking_tagline {
        font-size: $heading-3-font-size-lg;
      }
      .ranking_question {
        font-size: $heading-4-font-size-mb;
      }
      .ranking_advantages {
        font-size: $heading-4-font-size-mb;
      }

      .ranking_benefits {
        padding: 20px 0;
        color: $color-white;
      }
      .consultancy_tagline {
        font-size: $heading-5-font-size-lg;
      }

      .btnpart {
        padding: 50px 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .get_a_quote {
        padding: 10px 20px;
        color: white;
        background-color: $color-yellow;
        border-radius: 5px;
        margin: 0 10px;
        text-decoration: none;
      }
      .book_a_call {
        padding: 10px 20px;
        color: white;
        background-color: $color-yellow;
        border-radius: 5px;
        margin: 0 10px;
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c_product_ranking {
    height: 800px;
    background-image: url("../../../../static/header-2.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    .ranking_details {
      width: 90%;
      margin: 0 auto;
      text-align: center;
      color: $color-yellow;
      .amazon {
        background-color: $color-yellow;
        color: $color-black;
      }
      .ranking_title {
        font-size: $PRheader-heading-size;
      }
      .ranking_tagline {
        font-size: $heading-3-font-size-lg;
      }
      .ranking_question {
        font-size: $heading-4-font-size-mb;
      }
      .ranking_advantages {
        font-size: $heading-4-font-size-mb;
      }

      .ranking_benefits {
        padding: 20px 0;
        color: $color-white;
      }
      .consultancy_tagline {
        font-size: $heading-5-font-size-lg;
      }

      .btnpart {
        padding: 50px 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .get_a_quote {
        padding: 10px 20px;
        color: #000;
        background-color: $color-yellow;
        border-radius: 5px;
        margin: 0 10px;
        text-decoration: none;
      }
      .book_a_call {
        padding: 10px 20px;
        background-color: $color-yellow;
        border-radius: 5px;
        color: #000;
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }
}
