@import "../../ComponentStyles/components-styles.scss";

ul {
  padding: 0;
}

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-footer-section {
    background-color: $color-gray;
    width: 100%;
    .container {
      padding: 50px 40px 50px 40px;

      .parent-box-footer {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 30px;
        justify-items: center;
        grid-row-gap: 45px;
        .grid-items {
          width: 100%;
          .about-us-section {
            h2 {
              color: $color-white;

              font-weight: 600;
              padding-bottom: 15px;
            }
            p {
              font-size: 16px;
              font-weight: 400;
              color: #ffffff;
              word-spacing: 4px;
              text-align: left;
              line-height: 1.5rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4; /* number of lines to show */
              -webkit-box-orient: vertical;
            }
            hr {
              width: 15%;
              color: white;
              margin-bottom: 40px;
            }
            ul {
              display: flex;
              list-style-type: none;
              font-size: 35px;
              margin-top: 20px;

              svg {
                color: #ffffff;
                border: 1px solid white;
                border-radius: 50%;
                padding: 5px;
              }
              li {
                a {
                  padding-right: 20px;
                }
              }
            }
          }
          .get_in_section {
            h2 {
              color: $color-white;
              font-weight: 600;
              padding-bottom: 15px;
            }
            hr {
              width: 15%;
              color: white;
              margin-bottom: 40px;
            }
            ul {
              list-style-type: none;
              color: white;
              font-size: 16px;
            }
            svg {
              color: white;
              font-size: 25px;
            }
            a {
              padding-right: 16px;
            }
            li {
              display: flex;
              justify-content: flex-start;
            }
          }
          .useful-section {
            h2 {
              color: $color-white;
              font-weight: 600;
              padding-bottom: 15px;
            }
            hr {
              width: 15%;
              color: white;
              margin-bottom: 40px;
            }
            ul {
              list-style-type: none;
              color: white;
              font-size: 16px;
              line-height: 1.7;
            }
            a {
              padding-right: 16px;
              color: white;
              text-decoration: none;
            }
            li {
              display: flex;
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-footer-section {
    background-color: $color-gray;
    width: 100%;
    padding-bottom: 20px;
    .container {
      padding: 80px 40px 0 40px;

      .parent-box-footer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 45px;
        justify-items: center;
        grid-row-gap: 45px;
        .grid-items {
          width: 100%;
          .about-us-section {
            h2 {
              color: $color-white;
              font-weight: 600;
              padding-bottom: 15px;
            }
            p {
              font-size: 18px;
              font-weight: 400;
              color: #ffffff;
              word-spacing: 4px;
              text-align: left;
              line-height: 1.5rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4; /* number of lines to show */
              -webkit-box-orient: vertical;
            }
            hr {
              width: 15%;
              color: white;
              margin-bottom: 40px;
            }
            ul {
              display: flex;
              list-style-type: none;
              font-size: 35px;
              margin-top: 20px;

              svg {
                color: #ffffff;
                border: 1px solid white;
                border-radius: 50%;
                padding: 5px;
              }
              li {
                a {
                  padding-right: 20px;
                }
              }
            }
          }
          .get_in_section {
            h2 {
              color: $color-white;
              font-weight: 600;
              padding-bottom: 15px;
            }
            hr {
              width: 15%;
              color: white;
              margin-bottom: 40px;
            }
            ul {
              list-style-type: none;
              color: white;
              font-size: 18px;
            }
            svg {
              color: white;
              font-size: 25px;
            }
            a {
              padding-right: 16px;
            }
            li {
              display: flex;
              justify-content: flex-start;
            }
          }
          .useful-section {
            h2 {
              color: $color-white;
              font-weight: 600;
              padding-bottom: 15px;
            }
            hr {
              width: 15%;
              color: white;
              margin-bottom: 40px;
            }
            ul {
              list-style-type: none;
              color: white;
              font-size: 18px;
              line-height: 1.7;
            }
            a {
              padding-right: 16px;
              color: white;
              text-decoration: none;
            }
            li {
              display: flex;
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-footer-section {
    background-color: $color-gray;
    width: 100%;
    .container {
      padding: 35px 40px 40px 40px;

      .parent-box-footer {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 40px;
        justify-items: center;
        grid-row-gap: 45px;
        .grid-items {
          width: 100%;

          .about-us-section {
            h2 {
              color: $color-white;
              font-weight: 600;
              padding-bottom: 15px;
            }
            p {
              font-size: 18px;
              font-weight: 400;
              color: #ffffff;
              word-spacing: 2px;
              text-align: left;
              line-height: 1.5rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4; /* number of lines to show */
              -webkit-box-orient: vertical;
            }
            hr {
              width: 15%;
              color: white;
              margin-bottom: 40px;
            }
            ul {
              display: flex;
              list-style-type: none;
              font-size: 35px;
              margin-top: 20px;

              svg {
                color: #ffffff;
                border: 1px solid white;
                border-radius: 50%;
                padding: 5px;
              }
              li {
                a {
                  padding-right: 20px;
                }
              }
            }
          }
          .get_in_section {
            h2 {
              color: $color-white;

              font-weight: 600;
              padding-bottom: 15px;
            }
            hr {
              width: 15%;
              color: white;
              margin-bottom: 40px;
            }
            ul {
              list-style-type: none;
              color: white;
              font-size: 18px;
            }
            svg {
              color: white;
              font-size: 25px;
            }
            a {
              padding-right: 16px;
            }
            li {
              display: flex;
              justify-content: flex-start;
            }
          }
          .useful-section {
            h2 {
              color: $color-white;
              font-weight: 600;
              padding-bottom: 15px;
            }
            hr {
              width: 15%;
              color: white;
              margin-bottom: 40px;
            }
            ul {
              list-style-type: none;
              color: white;
              font-size: 16px;
              line-height: 1.7;
            }
            a {
              padding-right: 16px;
              color: white;
              text-decoration: none;
            }
            li {
              display: flex;
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-footer-section {
    background-color: $color-gray;
    width: 100%;
    .container {
      padding: 60px 40px 40px 40px;

      .parent-box-footer {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 100px;
        justify-items: center;
        grid-row-gap: 45px;
        .grid-items {
          width: 100%;
          .about-us-section {
            h2 {
              color: $color-white;
              font-weight: 600;
              padding-bottom: 15px;
            }
            p {
              font-size: 18px;
              font-weight: 400;
              color: #ffffff;
              word-spacing: 4px;
              text-align: left;
              line-height: 1.5rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4; /* number of lines to show */
              -webkit-box-orient: vertical;
            }
            hr {
              width: 15%;
              color: white;
              margin-bottom: 40px;
            }
            ul {
              display: flex;
              list-style-type: none;
              font-size: 35px;
              margin-top: 20px;

              svg {
                color: #ffffff;
                border: 1px solid white;
                border-radius: 50%;
                padding: 5px;
              }
              li {
                a {
                  padding-right: 20px;
                }
              }
            }
          }
          .get_in_section {
            h2 {
              color: $color-white;
              font-weight: 600;
              padding-bottom: 15px;
            }
            hr {
              width: 15%;
              color: white;
              margin-bottom: 40px;
            }
            ul {
              list-style-type: none;
              color: white;
              font-size: 18px;
            }
            svg {
              color: white;
              font-size: 25px;
            }
            a {
              padding-right: 16px;
            }
            li {
              display: flex;
              justify-content: flex-start;
            }
          }
          .useful-section {
            h2 {
              color: $color-white;
              font-weight: 600;
              padding-bottom: 15px;
            }
            hr {
              width: 15%;
              color: white;
              margin-bottom: 40px;
            }
            ul {
              list-style-type: none;
              color: white;
              font-size: 18px;
              line-height: 1.7;
            }
            a {
              padding-right: 16px;
              color: white;
              text-decoration: none;
            }
            li {
              display: flex;
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
}
