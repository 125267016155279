@import "../../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-ALO-why-brand-love-us {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    margin: 40px auto;
    .ALO-left {
      .brand-love-us-heading {
        font-size: $heading-1-font-size-mb;
        color: $color-yellow;
        text-align: center;
      }
      .brand-love-us-description {
        font-size: $heading-5-font-size-mb;
        font-weight: bold;
        margin: 15px 0;
        text-align: center;
      }
      .reason-list {
        .reason {
          display: flex;
          align-items: center;
          .list-icon {
            color: $color-yellow;
            font-weight: bold;
            font-size: $heading-3-font-size-mb;
            position: relative;
            top: 5px;
          }
          .reason-text {
            margin: 10px;
            font-weight: 500;
            font-size: $heading-6-font-size-mb;
          }
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-ALO-why-brand-love-us {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    margin: 40px auto;
    .ALO-left {
      .brand-love-us-heading {
        font-size: $heading-1-font-size-mb;
        color: $color-yellow;
        text-align: center;
      }
      .brand-love-us-description {
        font-size: $heading-5-font-size-mb;
        font-weight: bold;
        margin: 15px 0;
        text-align: center;
      }
      .reason-list {
        .reason {
          display: flex;
          align-items: center;
          .list-icon {
            color: $color-yellow;
            font-weight: bold;
            font-size: $heading-3-font-size-mb;
            position: relative;
            top: 5px;
          }
          .reason-text {
            margin: 10px;
            font-weight: 500;
            font-size: $heading-6-font-size-mb;
          }
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-ALO-why-brand-love-us {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    margin: 40px auto;
    .ALO-left {
      .brand-love-us-heading {
        font-size: $heading-1-font-size-lg;
        color: $color-yellow;
      }
      .brand-love-us-description {
        font-size: $heading-5-font-size-lg;
        font-weight: bold;
        margin: 15px 0;
      }
      .reason-list {
        .reason {
          display: flex;
          align-items: center;
          .list-icon {
            color: $color-yellow;
            font-weight: bold;
            font-size: $heading-2-font-size-mb;
            position: relative;
            top: 5px;
          }
          .reason-text {
            margin: 10px;
            font-weight: 500;
            font-size: $heading-5-font-size-mb;
          }
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-ALO-why-brand-love-us {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    width: 90%;
    margin: 40px auto;
    .ALO-left {
      .brand-love-us-heading {
        font-size: $heading-1-font-size-lg;
        color: $color-yellow;
      }
      .brand-love-us-description {
        font-size: $heading-5-font-size-lg;
        font-weight: bold;
        margin: 15px 0;
      }
      .reason-list {
        .reason {
          display: flex;
          align-items: center;
          .list-icon {
            color: $color-yellow;
            font-weight: bold;
            font-size: $heading-2-font-size-mb;
            position: relative;
            top: 5px;
          }
          .reason-text {
            margin: 10px;
            font-weight: 500;
            font-size: $heading-5-font-size-mb;
          }
        }
      }
    }
  }
}
