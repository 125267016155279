@import "../../../ComponentStyles/components-styles.scss";

$PRheader-heading-size: 64px;
$PRheader-heading-size-md: 52px;
$PRheader-heading-size-sm: 40px;
$PRheader-heading-size-xs: 28px;

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c_portfolio_section {
    padding: 10px;
    margin: 80px auto;
    .parent_gallery_images {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      .main_parent_image {
        text-align: center;
      }
      img {
        width: 100%;
      }
    }
    .parent_description {
      text-align: center;
      width: 80%;
      margin: 0 auto;
      .main_portfolio_title {
        font-size: $heading-1-font-size-mb;
      }
      .main_portfolio_description {
        font-size: $heading-5-font-size-mb;
        padding: 20px 0 20px 0;
      }
    }
    .react-tabs {
      .react-tabs__tab {
        bottom: 0;
      }
      .react-tabs__tab-list {
        text-align: center;
        border-bottom: none;
      }
      .react-tabs__tab--selected {
        border: 1px solid $color-yellow;
        border-radius: 0;
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c_portfolio_section {
    padding: 10px;
    margin: 80px auto;
    .parent_gallery_images {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      .main_parent_image {
        text-align: center;
      }
      img {
        width: 100%;
      }
    }
    .parent_description {
      text-align: center;
      width: 80%;
      margin: 0 auto;
      .main_portfolio_title {
        font-size: $heading-1-font-size-mb;
      }
      .main_portfolio_description {
        font-size: $heading-5-font-size-mb;
        padding: 20px 0 20px 0;
      }
    }
    .react-tabs {
      .react-tabs__tab-list {
        text-align: center;
        border-bottom: none;
      }
      .react-tabs__tab--selected {
        border: 1px solid $color-yellow;
        border-radius: 0;
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c_portfolio_section {
    padding: 20px;
    width: 90%;
    margin: 0 auto;

    .parent_gallery_images {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      align-items: center;
      justify-content: center;
      padding: 20px 0;

      img {
        width: 100%;
      }
    }
    .parent_description {
      text-align: center;
      width: 80%;
      margin: 0 auto;
      .main_portfolio_title {
        font-size: $heading-1-font-size-lg;
      }
      .main_portfolio_description {
        font-size: $heading-5-font-size-mb;
        padding: 20px 0 20px 0;
      }
    }
    .react-tabs {
      .react-tabs__tab-list {
        text-align: center;
        border-bottom: none;
      }
      .react-tabs__tab--selected {
        border: 1px solid $color-yellow;
        border-radius: 0;
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c_portfolio_section {
    padding: 20px;
    width: 90%;
    margin: 0 auto;

    .parent_gallery_images {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      align-items: center;
      justify-content: center;
      padding: 20px 0;

      img {
        width: 100%;
      }
    }
    .parent_description {
      text-align: center;
      width: 80%;
      margin: 0 auto;
      .main_portfolio_title {
        font-size: $heading-1-font-size-lg;
      }
      .main_portfolio_description {
        font-size: $heading-5-font-size-mb;
        padding: 20px 0 20px 0;
      }
    }
    .react-tabs {
      .react-tabs__tab-list {
        text-align: center;
        border-bottom: none;
      }
      .react-tabs__tab--selected {
        border: 1px solid $color-yellow;
        border-radius: 0;
      }
    }
  }
}
