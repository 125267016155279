@import "../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-fixed-background {
    height: 300px;
    background: fixed;
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    .background-content {
      color: $color-yellow;
      text-align: center;
      font-size: $heading-2-font-size-mb;
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-fixed-background {
    height: 300px;
    background: fixed;
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .background-content {
      color: $color-yellow;
      text-align: center;
      font-size: $heading-1-font-size-mb;
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-fixed-background {
    height: 500px;
    background: fixed;
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .background-content {
      color: $color-yellow;
      font-size: calc($heading-1-font-size-lg * 1.4);
      text-align: center;
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-fixed-background {
    height: 500px;
    background: fixed;
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .background-content {
      color: $color-yellow;
      font-size: calc($heading-1-font-size-lg * 1.4);
      text-align: center;
    }
  }
}
