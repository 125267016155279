@import "../../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-ALOHeader {
    padding: 10px;
    min-height: 400px;
    background-image: url("../../../../static/header-2.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ALOHeader-wrapper {
      text-align: center;
      color: $color-yellow;

      .main-heading {
        font-size: $heading-1-font-size-lg;
      }
      .main-description {
        font-size: $heading-5-font-size-mb;
        margin: 20px 0;
        color: $color-white;
      }
      .ALOHeader-btn {
        margin: 40px 0;
        .action-btns {
          background-color: $color-yellow;
          font-weight: 700;
          text-transform: uppercase;
          text-decoration: none;
          color: #232f3e;
          padding: 10px 20px;
          margin: 0 20px 0 0;
          cursor: pointer;
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-ALOHeader {
    padding: 10px;
    min-height: 400px;
    background-image: url("../../../../static/header-2.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ALOHeader-wrapper {
      text-align: center;
      color: $color-yellow;

      .main-heading {
        font-size: $heading-1-font-size-lg;
      }
      .main-description {
        font-size: $heading-5-font-size-mb;
        margin: 20px 0;
        color: $color-white;
      }
      .ALOHeader-btn {
        margin: 40px 0;
        .action-btns {
          background-color: $color-yellow;
          font-weight: 700;
          text-transform: uppercase;
          text-decoration: none;
          color: #232f3e;
          padding: 10px 20px;
          margin: 0 20px 0 0;
          cursor: pointer;
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-ALOHeader {
    padding: 20px;
    min-height: 800px;
    background-image: url("../../../../static/header-2.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ALOHeader-wrapper {
      text-align: center;
      color: $color-yellow;

      .main-heading {
        font-size: calc($heading-1-font-size-lg * 1.5);
      }
      .main-description {
        font-size: $heading-4-font-size-lg;
        margin: 20px 0;
        color: $color-white;
      }
      .ALOHeader-btn {
        margin: 40px 0;
        .action-btns {
          background-color: $color-yellow;
          font-weight: 700;
          text-transform: uppercase;
          text-decoration: none;
          color: #232f3e;
          padding: 10px 20px;
          margin: 0 20px 0 0;
          cursor: pointer;
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-ALOHeader {
    padding: 20px;
    min-height: 800px;
    background-image: url("../../../../static/header-2.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ALOHeader-wrapper {
      text-align: center;
      color: $color-yellow;

      .main-heading {
        font-size: calc($heading-1-font-size-lg * 1.5);
      }
      .main-description {
        font-size: $heading-4-font-size-lg;
        margin: 20px 0;
        color: $color-white;
      }
      .ALOHeader-btn {
        margin: 40px 0;
        .action-btns {
          background-color: $color-yellow;
          font-weight: 700;
          text-transform: uppercase;
          text-decoration: none;
          color: #232f3e;
          padding: 10px 20px;
          margin: 0 20px 0 0;
          cursor: pointer;
        }
      }
    }
  }
}
