@import "../../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-ppc-service {
    padding: 10px;
    margin: 40px auto;

    .ppc-service-heading {
      font-size: $heading-1-font-size-mb;
      text-align: center;
      color: $color-yellow;
    }
    .ppc-service-container {
      margin: 20px 0;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 40px;
      text-align: center;
    }
  }
  .c-ppc-service-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 20px 10px;
    transition: 0.2s;

    &:hover {
      transform: translate(0px, -10px);
      background-color: $color-yellow;
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-ppc-service {
    padding: 10px;
    margin: 40px auto;

    .ppc-service-heading {
      font-size: $heading-1-font-size-mb;
      text-align: center;
      color: $color-yellow;
    }
    .ppc-service-container {
      margin: 20px 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      text-align: center;
    }
  }
  .c-ppc-service-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 20px 10px;
    transition: 0.2s;

    &:hover {
      transform: translate(0px, -10px);
      background-color: $color-yellow;
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-ppc-service {
    padding: 20px;
    margin: 40px auto;

    .ppc-service-heading {
      font-size: $heading-1-font-size-lg;
      text-align: center;
      color: $color-yellow;
    }
    .ppc-service-container {
      margin: 40px 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }
  }
  .c-ppc-service-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      transform: translate(0px, -10px);
      background-color: $color-yellow;
    }

    .ppc-card-bottom {
      .description {
        font-size: $text-font-size-lg;
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-ppc-service {
    padding: 20px;
    width: 90%;
    margin: 40px auto;

    .ppc-service-heading {
      font-size: $heading-1-font-size-lg;
      text-align: center;
      color: $color-yellow;
    }
    .ppc-service-container {
      margin: 40px 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 40px;
      grid-row-gap: 40px;
    }
  }
  .c-ppc-service-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      transform: translate(0px, -10px);
      background-color: $color-yellow;
    }

    .ppc-card-bottom {
      .description {
        font-size: $text-font-size-lg;
      }
    }
  }
}
