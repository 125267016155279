@import "../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-accordion {
    // remove this when you used in your component or adjust accordinly
    padding: 10px;
    .accordion {
      color: black;
      background-color: white;

      .top {
        padding-bottom: 30px;

        .hr {
          padding: 3px;
          background-color: $color-yellow;
          width: 100px;
          display: none;
        }
        .section-title {
          font-size: $heading-1-font-size-mb;
          text-align: center;
        }
      }
    }
    .accordion .container {
      position: relative;
      margin: 10px 10px;
    }

    /* Positions the labels relative to the .container. Adds padding to the top and bottom and increases font size. Also makes its cursor a pointer */

    .accordion .label {
      position: relative;
      padding: 10px;
      font-size: $heading-5-font-size-mb;
      cursor: pointer;
      font-weight: bold;
      background-color: $color-gray;
      color: $color-yellow;
    }
    .accordion .label::before {
      content: "+";
      color: $color-yellow;
      position: absolute;
      top: 50%;
      right: 10px;
      font-size: $heading-5-font-size-mb;
      transform: translateY(-50%);
    }

    /* Hides the content (height: 0), decreases font size, justifies text and adds transition */

    .accordion .content {
      background: white;
      max-height: 0;
      font-size: $text-font-size-lg;
      overflow: hidden;
      transition: 0.5s;
    }

    /* Adds a horizontal line between the contents */

    .accordion hr {
      width: 100;
      margin-left: 0;
    }
    .accordion .container.active .content {
      max-height: 300px;
      transition: 0.5s;
      padding: 20px;
      border: 1px solid $color-gray;
    }

    /* Changes from plus sign to negative sign once active */

    .accordion .container.active .label::before {
      content: "-";
      font-size: 30px;
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-accordion {
    // remove this when you used in your component or adjust accordinly
    padding: 10px;
    margin: 20px auto;
    .accordion {
      color: black;
      background-color: white;
      .top {
        padding-bottom: 30px;

        .hr {
          padding: 3px;
          background-color: $color-yellow;
          width: 100px;
          display: none;
        }
        .section-title {
          font-size: $heading-1-font-size-mb;
          text-align: center;
        }
      }
    }
    .accordion .container {
      position: relative;
      margin: 10px 10px;
    }

    /* Positions the labels relative to the .container. Adds padding to the top and bottom and increases font size. Also makes its cursor a pointer */

    .accordion .label {
      position: relative;
      padding: 10px;
      font-size: $heading-5-font-size-mb;
      cursor: pointer;
      font-weight: bold;
      background-color: $color-gray;
      color: $color-yellow;
    }
    .accordion .label::before {
      content: "+";
      color: $color-yellow;
      position: absolute;
      top: 50%;
      right: 10px;
      font-size: $heading-5-font-size-mb;
      transform: translateY(-50%);
    }

    /* Hides the content (height: 0), decreases font size, justifies text and adds transition */

    .accordion .content {
      background: white;
      max-height: 0;
      font-size: $text-font-size-lg;
      overflow: hidden;
      transition: 0.5s;
    }

    /* Adds a horizontal line between the contents */

    .accordion hr {
      width: 100;
      margin-left: 0;
    }
    .accordion .container.active .content {
      max-height: 300px;
      transition: 0.5s;
      padding: 20px;
      border: 1px solid $color-gray;
    }

    /* Changes from plus sign to negative sign once active */

    .accordion .container.active .label::before {
      content: "-";
      font-size: 30px;
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-accordion {
    // remove this when you used in your component or adjust accordinly
    padding: 20px;
    margin: 0px auto;

    .accordion {
      color: black;
      background-color: white;

      .top {
        padding: 0 0 50px 0;
        .hr {
          padding: 3px;
          background-color: $color-yellow;
          width: 100px;
        }
        .section-title {
          font-size: $heading-1-font-size-lg;
        }
      }
    }
    .accordion .container {
      position: relative;
      margin: 10px 10px;
    }

    /* Positions the labels relative to the .container. Adds padding to the top and bottom and increases font size. Also makes its cursor a pointer */

    .accordion .label {
      position: relative;
      padding: 10px;
      font-size: $heading-4-font-size-lg;
      cursor: pointer;
      font-weight: bold;
      background-color: $color-gray;
      color: $color-yellow;
    }
    .accordion .label::before {
      content: "+";
      color: $color-yellow;
      position: absolute;
      top: 50%;
      right: 10px;
      font-size: 30px;
      transform: translateY(-50%);
    }

    /* Hides the content (height: 0), decreases font size, justifies text and adds transition */

    .accordion .content {
      background: white;
      max-height: 0;
      font-size: $text-font-size-lg;
      overflow: hidden;
      transition: 0.5s;
      border: 1px solid $color-gray;
    }

    /* Adds a horizontal line between the contents */

    .accordion hr {
      width: 100;
      margin-left: 0;
    }
    .accordion .container.active .content {
      max-height: 300px;
      transition: 0.5s;
      padding: 20px;
    }

    /* Changes from plus sign to negative sign once active */

    .accordion .container.active .label::before {
      content: "-";
      font-size: 30px;
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-accordion {
    // remove this when you used in your component or adjust accordinly

    .accordion {
      color: black;
      background-color: white;
      .top {
        padding: 0 0 50px 0;
        .hr {
          padding: 3px;
          background-color: $color-yellow;
          width: 100px;
        }
        .section-title {
          font-size: $heading-1-font-size-lg;
        }
      }
    }
    .accordion .container {
      position: relative;
      margin: 10px 0;
    }

    /* Positions the labels relative to the .container. Adds padding to the top and bottom and increases font size. Also makes its cursor a pointer */

    .accordion .label {
      position: relative;
      padding: 10px;
      font-size: $heading-4-font-size-lg;
      cursor: pointer;
      font-weight: bold;
      background-color: $color-gray;
      color: $color-yellow;
    }
    .accordion .label::before {
      content: "+";
      color: $color-yellow;
      position: absolute;
      top: 50%;
      right: 10px;
      font-size: 30px;
      transform: translateY(-50%);
    }

    /* Hides the content (height: 0), decreases font size, justifies text and adds transition */

    .accordion .content {
      background: white;
      max-height: 0;
      font-size: $text-font-size-lg;
      transition: 0.2s;
      overflow: hidden;
    }

    /* Adds a horizontal line between the contents */

    .accordion hr {
      width: 100;
      margin-left: 0;
    }
    .accordion .container.active .content {
      max-height: 300px;
      transition: 0.5s;
      padding: 20px;
      border: 1px solid $color-gray;
    }

    /* Changes from plus sign to negative sign once active */

    .accordion .container.active .label::before {
      content: "-";
      font-size: 30px;
    }
  }
}
