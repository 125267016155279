@import "../../ComponentStyles/components-styles.scss";

//    for mobile devices
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-contactus-section {
    background-color: $color-gray;
    width: 100%;
    margin: 0 0 50px 0;
    .parent-contact-us {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      justify-items: center;
      padding: 15px 10px;
      .grid-items {
        width: 100%;
        background-color: $color-white;
        padding: 50px 20px;
        .company_details {
          .social_links_parent {
            display: flex;
            list-style-type: none;
            font-size: $heading-4-font-size-lg;
            #social_linking {
              padding-right: 20px;
            }
          }
          .company_details_parent {
            list-style-type: none;
            font-size: $heading-6-font-size-lg;
            padding: 30px 0;
            .company_details_child {
              padding: 9px 0;
              display: flex;
              &:last-child {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4; /* number of lines to show */
                -webkit-box-orient: vertical;
              }
              #company_link {
                padding-right: 15px;
              }
            }
          }
          .main_head {
            font-size: $heading-4-font-size-lg;
            color: $color-black;
            font-weight: bold;
          }
          svg {
            color: $color-yellow;
          }
        }
        .company_contact_form {
          .input_field {
            #contact_input {
              display: flex;
              padding: 15px 20px;
              border: 1px solid $color-black;
              width: 100%;
              margin-bottom: 1rem;
              outline: none;
              font-weight: 700;
              color: $color-gray;
              font-size: $heading-6-font-size-lg;
            }
          }
          .text_area {
            display: flex;
            padding: 10px 20px;
            border: 1px solid black;
            width: 100%;
            margin-bottom: 1rem;
            outline: none;
            font-weight: 700;
            color: $color-gray;
            font-size: $heading-6-font-size-lg;
          }
          .submit_btn {
            padding: 15px 20px;
            border: 1px solid black;
            background-color: $color-yellow;
            width: 100%;
            margin-bottom: 1rem;
            font-weight: 700;
            color: $color-gray;
            text-align: center;
            cursor: pointer;
            font-size: $heading-6-font-size-lg;
            &:disabled {
              background-color: #d5d5d5 !important;
            }
          }
          .error {
            background-color: #e57373;
            margin: 10px 0;
            padding: 10px 20px;
            color: white;
          }
          .success {
            background-color: $color-yellow;
            margin: 10px 0;
            padding: 10px 20px;
            color: white;
          }
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-contactus-section {
    background-color: $color-gray;
    width: 100%;
    margin: 0 0 50px 0;
    .parent-contact-us {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      justify-items: center;
      padding: 50px 20px;
      .grid-items {
        width: 100%;
        background-color: $color-white;
        padding: 50px 40px;

        .company_details {
          .social_links_parent {
            display: flex;
            list-style-type: none;
            font-size: $heading-4-font-size-lg;

            #social_linking {
              padding-right: 20px;
            }
          }
          .company_details_parent {
            list-style-type: none;
            font-size: $heading-5-font-size-lg;
            padding: 30px 0;

            .company_details_child {
              padding: 9px 0;
              display: flex;
              &:last-child {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4; /* number of lines to show */
                -webkit-box-orient: vertical;
              }
              #company_link {
                padding-right: 15px;
              }
            }
          }
          .main_head {
            font-size: $heading-4-font-size-lg;
            color: $color-black;
            font-weight: bold;
          }
          svg {
            color: $color-yellow;
          }
        }

        .company_contact_form {
          .input_field {
            #contact_input {
              display: flex;
              padding: 15px 20px;
              border: 1px solid $color-black;
              width: 100%;
              margin-bottom: 1rem;
              outline: none;
              font-weight: 700;
              color: $color-gray;
            }
          }
          .text_area {
            display: flex;
            padding: 10px 20px;
            border: 1px solid black;
            width: 100%;
            margin-bottom: 1rem;
            outline: none;
            font-weight: 700;
            color: $color-gray;
          }
          .submit_btn {
            padding: 15px 20px;
            border: 1px solid black;
            background-color: $color-yellow;
            width: 100%;
            margin-bottom: 1rem;
            font-weight: 700;
            color: $color-gray;
            text-align: center;
            &:disabled {
              background-color: #d5d5d5 !important;
            }
          }
          .error {
            background-color: #e57373;
            margin: 10px 0;
            padding: 10px 20px;
            color: white;
          }
          .success {
            background-color: $color-yellow;
            margin: 10px 0;
            padding: 10px 20px;
            color: white;
          }
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-contactus-section {
    background-color: $color-gray;
    width: 100%;
    height: 675px;
    margin: 0 0 50px 0;
    .parent-contact-us {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      justify-items: center;
      grid-row-gap: 45px;
      padding: 100px 30px;
      .grid-items {
        width: 100%;
        background-color: $color-white;
        padding: 50px 40px;

        .company_details {
          .social_links_parent {
            display: flex;
            list-style-type: none;
            font-size: $heading-4-font-size-lg;

            #social_linking {
              padding-right: 20px;
            }
          }
          .company_details_parent {
            list-style-type: none;
            font-size: $heading-5-font-size-lg;
            padding: 30px 0;

            .company_details_child {
              padding: 9px 0;
              display: flex;
              &:last-child {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4; /* number of lines to show */
                -webkit-box-orient: vertical;
              }
              #company_link {
                padding-right: 15px;
              }
            }
          }
          .main_head {
            font-size: $heading-4-font-size-lg;
            color: $color-black;
            font-weight: bold;
          }
          svg {
            color: $color-yellow;
          }
        }

        .company_contact_form {
          .input_field {
            #contact_input {
              display: flex;
              padding: 15px 20px;
              border: 1px solid $color-black;
              width: 100%;
              margin-bottom: 1rem;
              outline: none;
              font-weight: 700;
              color: $color-gray;
            }
          }
          .text_area {
            display: flex;
            padding: 10px 20px;
            border: 1px solid black;
            width: 100%;
            margin-bottom: 1rem;
            outline: none;
            font-weight: 700;
            color: $color-gray;
          }
          .submit_btn {
            padding: 15px 20px;
            border: 1px solid black;
            background-color: $color-yellow;
            width: 100%;
            margin-bottom: 1rem;
            font-weight: 700;
            color: $color-gray;
            text-align: center;
            cursor: pointer;
            &:disabled {
              background-color: #d5d5d5 !important;
            }
          }
          .error {
            background-color: #e57373;
            margin: 10px 0;
            padding: 10px 20px;
            color: white;
          }
          .success {
            background-color: $color-yellow;
            margin: 10px 0;
            padding: 10px 20px;
            color: white;
          }
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-contactus-section {
    background-color: $color-gray;
    width: 100%;
    height: 675px;
    margin: 0 0 50px 0;
    .parent-contact-us {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      justify-items: center;
      grid-row-gap: 45px;
      padding: 100px 150px;
      .grid-items {
        width: 100%;
        background-color: $color-white;
        padding: 50px 40px;

        .company_details {
          .social_links_parent {
            display: flex;
            list-style-type: none;
            font-size: $heading-4-font-size-lg;

            #social_linking {
              padding-right: 20px;
            }
          }
          .company_details_parent {
            list-style-type: none;
            font-size: $heading-5-font-size-lg;
            padding: 30px 0;

            .company_details_child {
              padding: 9px 0;
              display: flex;
              &:last-child {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4; /* number of lines to show */
                -webkit-box-orient: vertical;
              }
              #company_link {
                padding-right: 15px;
              }
            }
          }
          .main_head {
            font-size: $heading-4-font-size-lg;
            color: $color-black;
            font-weight: bold;
          }
          svg {
            color: $color-yellow;
          }
        }

        .company_contact_form {
          .input_field {
            #contact_input {
              display: flex;
              padding: 15px 20px;
              border: 1px solid $color-black;
              width: 100%;
              margin-bottom: 1rem;
              outline: none;
              font-weight: 700;
              color: $color-gray;
            }
          }
          .text_area {
            display: flex;
            padding: 10px 20px;
            border: 1px solid black;
            width: 100%;
            margin-bottom: 1rem;
            outline: none;
            font-weight: 700;
            color: $color-gray;
          }
          .submit_btn {
            padding: 15px 20px;
            border: 1px solid black;
            background-color: $color-yellow;
            width: 100%;
            margin-bottom: 1rem;
            font-weight: 700;
            color: $color-gray;
            text-align: center;
            cursor: pointer;

            &:disabled {
              background-color: #d5d5d5 !important;
            }
          }
          .error {
            background-color: #e57373;
            margin: 10px 0;
            padding: 10px 20px;
            color: white;
          }
          .success {
            background-color: $color-yellow;
            margin: 10px 0;
            padding: 10px 20px;
            color: white;
          }
        }
      }
    }
  }
}
