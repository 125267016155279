@import "../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-projects-done-counter {
    padding: 40px 10px;
    margin: 80px auto;

    position: relative;

    .blob-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    .top {
      .hr {
        display: none;
      }
      .section-title {
        font-size: $heading-1-font-size-mb;
        text-align: center;
      }
    }
    .section-counter {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 40px;

      margin: 20px auto;
      .counter-card {
        text-align: center;
        .project-icon {
          width: 64px;
          height: 64px;
        }
        .counter-content {
          .counter-count {
            font-size: $heading-1-font-size-mb;
          }
          .counter-project-title {
            font-size: $heading-4-font-size-mb;
            font-weight: bold;
            margin: 10px 0;
          }
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-projects-done-counter {
    padding: 40px 20px;
    margin: 80px auto;

    position: relative;

    .blob-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    .top {
      .hr {
        display: none;
      }
      .section-title {
        font-size: $heading-1-font-size-mb;
        text-align: center;
      }
    }
    .section-counter {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 60px;

      margin: 20px auto;
      .counter-card {
        text-align: center;
        .project-icon {
          width: 80px;
          height: 80px;
        }
        .counter-content {
          .counter-count {
            font-size: $heading-1-font-size-mb;
          }
          .counter-project-title {
            font-size: $heading-4-font-size-mb;
            font-weight: bold;
            margin: 10px 0;
          }
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-projects-done-counter {
    padding: 40px 20px;
    position: relative;

    .blob-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    .product-done-inner-container {
      .top {
        .hr {
          padding: 3px;
          background-color: $color-yellow;
          width: 100px;
        }
        .section-title {
          font-size: $heading-1-font-size-mb;
        }
      }
      .section-counter {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        margin: 40px auto;
        .counter-card {
          .project-icon {
            width: 64px;
            height: 64px;
          }
          .counter-content {
            .counter-count {
              font-size: $heading-1-font-size-mb;
            }
            .counter-project-title {
              font-size: $heading-4-font-size-mb;
              font-weight: bold;
              margin: 5px 0;
            }
          }
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-projects-done-counter {
    padding: 40px 20px;

    position: relative;

    .blob-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    .product-done-inner-container {
      width: 90%;
      margin: 80px auto;
      .top {
        .hr {
          padding: 3px;
          background-color: $color-yellow;
          width: 100px;
        }
        .section-title {
          font-size: $heading-1-font-size-lg;
        }
      }
      .section-counter {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 0px;

        margin: 40px auto;
        .counter-card {
          .project-icon {
            width: 80px;
            height: 80px;
          }
          .counter-content {
            .counter-count {
              font-size: $heading-1-font-size-lg;
            }
            .counter-project-title {
              font-size: $heading-4-font-size-lg;
              font-weight: bold;
              margin: 10px 0;
            }
          }
        }
      }
    }
  }
}
