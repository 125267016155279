@import "../../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-how-it-works-1 {
    padding: 10px;
    margin: 80px auto;
    .header {
      text-align: center;
      #heading {
        font-size: $heading-1-font-size-mb;
      }
      .description {
        font-size: $text-font-size-lg;
      }
    }
    .body {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-row-gap: 10px;
    }
  }

  .how-it-work-card {
    .wrapper {
      position: relative;
      .overlay {
        display: none;
      }
      .content {
        padding: 10px;
        z-index: 100;
        text-align: center;
        .card-heading {
          font-size: $heading-4-font-size-mb;
          margin-bottom: 10px;
        }
        .icon {
          background-color: rgba($color-yellow, 0.3);
          display: flex;
          margin-bottom: 10px;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          margin: 10px auto;
          height: 80px;
          width: 80px;
          font-size: calc($heading-1-font-size-lg * 1.3);
          border-radius: 50%;
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-how-it-works-1 {
    padding: 10px;
    margin: 80px auto;
    .header {
      text-align: center;
      #heading {
        font-size: $heading-1-font-size-mb;
      }
      .description {
        font-size: $text-font-size-lg;
      }
    }
    .body {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-row-gap: 10px;
    }
  }

  .how-it-work-card {
    .wrapper {
      position: relative;
      .overlay {
        display: none;
      }
      .content {
        padding: 10px;
        z-index: 100;
        text-align: center;
        .card-heading {
          font-size: $heading-4-font-size-mb;
          margin-bottom: 10px;
        }
        .icon {
          background-color: rgba($color-yellow, 0.3);
          display: flex;
          margin-bottom: 10px;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          margin: 10px auto;
          height: 80px;
          width: 80px;
          font-size: calc($heading-1-font-size-lg * 1.3);
          border-radius: 50%;
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-how-it-works-1 {
    padding: 20px;
    margin: 80px auto;
    .header {
      display: grid;
      grid-template-columns: 3fr 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 0px;
      align-items: center;
      .hr {
        padding: 3px;
        background-color: $color-yellow;
        width: 100px;
      }
      #heading {
        font-size: $heading-1-font-size-lg;
      }
      .description {
        font-size: $heading-5-font-size-lg;
      }
    }
    .body {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 0px;
      margin: 40px 0;
    }
  }

  .how-it-work-card {
    .wrapper {
      position: relative;
      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -9;
        .card-number-id {
          font-size: calc($heading-1-font-size-lg * 3);
          color: $color-gray;
          opacity: 0.1;
          text-align: right;
        }
      }
      .content {
        z-index: 100;
        .card-heading {
          font-size: $heading-4-font-size-lg;
          margin-bottom: 10px;
        }
        .icon {
          background-color: rgba($color-yellow, 0.3);
          display: flex;
          margin-bottom: 10px;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          height: 60px;
          width: 60px;
          font-size: $heading-1-font-size-lg;
          border-radius: 50%;
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-how-it-works-1 {
    padding: 20px;
    width: 90%;
    margin: 80px auto;
    .header {
      display: grid;
      grid-template-columns: 3fr 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 0px;
      align-items: center;
      .hr {
        padding: 3px;
        background-color: $color-yellow;
        width: 100px;
      }
      #heading {
        font-size: $heading-1-font-size-lg;
      }
      .description {
        font-size: $heading-5-font-size-lg;
      }
    }
    .body {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 0px;
      margin: 40px 0;
    }
  }

  .how-it-work-card {
    .wrapper {
      position: relative;
      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -9;
        .card-number-id {
          font-size: calc($heading-1-font-size-lg * 3);
          color: $color-gray;
          opacity: 0.1;
          text-align: right;
        }
      }
      .content {
        z-index: 100;
        .card-heading {
          font-size: $heading-4-font-size-lg;
          margin-bottom: 10px;
        }
        .icon {
          background-color: rgba($color-yellow, 0.3);
          display: flex;
          margin-bottom: 10px;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          height: 60px;
          width: 60px;
          font-size: $heading-1-font-size-lg;
          border-radius: 50%;
        }
      }
    }
  }
}
