@import "../../../ComponentStyles/components-styles.scss";

$PRheader-heading-size: 64px;
$PRheader-heading-size-md: 52px;
$PRheader-heading-size-sm: 40px;
$PRheader-heading-size-xs: 28px;

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c_one_step_photography_section {
    background-color: #f1f1f1;
    width: 100%;
    .parent_one_step_photography {
      width: 90%;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 50px;
      grid-row-gap: 0px;
      margin: 0 auto;
      align-items: center;
      justify-content: center;

      .one_step_description_section {
        .title {
          font-size: $PRheader-heading-size-xs;
          color: $color-yellow;
          padding: 30px 0;
        }
        .description {
          line-height: 30px;
          font-weight: 500;
        }
      }
      .one_step_image_section {
        .img {
          width: 100%;
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c_one_step_photography_section {
    background-color: #f1f1f1;
    width: 100%;
    .parent_one_step_photography {
      width: 90%;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 50px;
      grid-row-gap: 0px;
      margin: 0 auto;
      align-items: center;
      justify-content: center;

      .one_step_description_section {
        .title {
          font-size: $PRheader-heading-size-sm;
          color: $color-yellow;
          padding: 30px 0;
        }
        .description {
          line-height: 30px;
          font-weight: 500;
        }
      }
      .one_step_image_section {
        .img {
          width: 100%;
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c_one_step_photography_section {
    background-color: #f1f1f1;
    width: 100%;
    .parent_one_step_photography {
      width: 90%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 50px;
      grid-row-gap: 0px;
      margin: 0 auto;
      align-items: center;
      justify-content: center;

      .one_step_description_section {
        .title {
          font-size: $PRheader-heading-size-sm;
          color: $color-yellow;
          padding: 30px 0;
        }
        .description {
          line-height: 30px;
          font-weight: 500;
        }
      }
      .one_step_image_section {
        .img {
          width: 100%;
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c_one_step_photography_section {
    background-color: #f1f1f1;
    width: 100%;
    .parent_one_step_photography {
      width: 90%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 50px;
      grid-row-gap: 0px;
      margin: 0 auto;
      align-items: center;
      justify-content: center;

      .one_step_description_section {
        .title {
          font-size: $PRheader-heading-size-sm;
          color: $color-yellow;
          padding: 30px 0;
        }
        .description {
          line-height: 30px;
          font-weight: 500;
        }
      }
      .one_step_image_section {
        .img {
          width: 100%;
        }
      }
    }
  }
}
