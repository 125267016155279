* {
  margin: 0;
  font-family: "Manrope", sans-serif;
  box-sizing: border-box;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
}

html, body {
  overflow-x: hidden;
}
body {
  position: relative
}