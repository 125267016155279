@import "../../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-ppc-header {
    padding: 10px;
    height: 400px;
    background-image: url("../../../../static/ppc-manage.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ppc-header-heading {
      font-size: $heading-2-font-size-mb;
      color: $color-yellow;
    }

    .ppc-header-consultancy-btn {
      background-color: $color-yellow;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none;
      color: $color-gray;
      padding: 10px 20px;
      margin: 20px 0;
      font-size: $text-font-size-lg;
      cursor: pointer;
    }
    .ppc-service-highlights {
      padding: 10px;
      .ppc-service-item {
        color: white;
        display: flex;
        text-align: left;
        font-size: $heading-5-font-size-mb;
        margin: 5px 0;
        .ppc-service-item-icon {
          position: relative;
          top: 5px;
          left: -10px;
          color: $color-yellow;
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-ppc-header {
    padding: 10px;
    height: 400px;
    background-image: url("../../../../static/ppc-manage.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ppc-header-heading {
      font-size: $heading-1-font-size-mb;
      color: $color-yellow;
    }

    .ppc-header-consultancy-btn {
      background-color: $color-yellow;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none;
      color: $color-gray;
      padding: 10px 20px;
      margin: 20px 0;
      font-size: $text-font-size-lg;
      cursor: pointer;
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-ppc-header {
    padding: 20px;
    height: 800px;
    background-image: url("../../../../static/ppc-manage.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ppc-header-heading {
      font-size: calc($heading-1-font-size-lg * 1.6);
      color: $color-yellow;
    }

    .ppc-header-consultancy-btn {
      background-color: $color-yellow;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none;
      color: $color-gray;
      padding: 10px 20px;
      margin: 20px 0;
      font-size: $text-font-size-lg;
      cursor: pointer;
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-ppc-header {
    padding: 20px;
    height: 800px;
    background-image: url("../../../../static/ppc-manage.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ppc-header-heading {
      font-size: calc($heading-1-font-size-lg * 1.6);
      color: $color-yellow;
    }

    .ppc-header-consultancy-btn {
      background-color: $color-yellow;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none;
      color: $color-gray;
      padding: 10px 20px;
      margin: 20px 0;
      font-size: $text-font-size-lg;
      cursor: pointer;
    }

    .ppc-service-highlights {
      padding: 20px;
      .ppc-service-item {
        color: white;
        display: flex;
        align-items: center;
        font-size: $heading-4-font-size-lg;

        .ppc-service-item-icon {
          position: relative;
          top: 5px;
          left: -10px;
          color: $color-yellow;
        }
      }
    }
  }
}
