@import "../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-optimization-audit {
    background-color: $color-yellow;

    padding: 10px;
    .inner-wrapper {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 40px;
      margin: 80px auto;

      .left {
        text-align: center;
        .hr {
          display: none;
        }
        .section-title {
          font-size: $heading-1-font-size-mb;
        }
        .section-description {
          font-size: $text-font-size-mb;
          font-weight: 600;
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-optimization-audit {
    background-color: $color-yellow;

    padding: 10px;
    .inner-wrapper {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 40px;
      margin: 80px auto;

      .left {
        text-align: center;
        .hr {
          display: none;
        }
        .section-title {
          font-size: $heading-1-font-size-mb;
        }
        .section-description {
          font-size: $text-font-size-mb;
          font-weight: 600;
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-optimization-audit {
    background-color: $color-yellow;

    padding: 20px;
    .inner-wrapper {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 0px;
      margin: 80px auto;

      .left {
        .hr {
          background-color: $color-gray;
          padding: 3px;
          width: 100px;
        }
        .section-title {
          font-size: $heading-3-font-size-lg;
        }
        .section-description {
          font-size: $text-font-size-lg;
          font-weight: 600;
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-optimization-audit {
    background-color: $color-yellow;

    padding: 20px;
    .inner-wrapper {
      width: 90%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 0px;
      margin: 80px auto;

      .left {
        .hr {
          background-color: $color-gray;
          padding: 3px;
          width: 100px;
        }
        .section-title {
          font-size: $heading-1-font-size-lg;
        }
        .section-description {
          font-size: $text-font-size-lg;
          font-weight: 600;
        }
      }
    }
  }
}
