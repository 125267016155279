@import "../../../ComponentStyles/components-styles.scss";

$PRheader-heading-size: 64px;
$PRheader-heading-size-md: 52px;
$PRheader-heading-size-sm: 40px;
$PRheader-heading-size-xs: 28px;

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c_amazon_ebc_portfolio_section {
    .parent_ebc_portfolio_description {
      text-align: center;
      padding: 30px 20px;
      .title {
        font-size: $heading-1-font-size-lg;
        color: $color-yellow;
        line-height: 80px;
      }
      .desc {
        font-size: 18px;
        padding: 0 0 30px 0;
      }
    }
  }
  .parent_ebc_portfolio_image {
    .images {
      width: 100%;
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c_amazon_ebc_portfolio_section {
    margin: 50px auto 0;
    padding: 20px 0;
    .parent_ebc_portfolio_description {
      text-align: center;
      margin: 0 auto;
      padding: 0 40px;
      .title {
        font-size: $heading-1-font-size-lg;
        color: $color-yellow;
        line-height: 80px;
      }
      .desc {
        font-size: 18px;
        padding: 0 0 30px 0;
      }
    }
  }
  .parent_ebc_portfolio_image {
    .images {
      width: 100%;
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c_amazon_ebc_portfolio_section {
    margin: 50px auto 0;
    padding: 20px 0;
    .parent_ebc_portfolio_description {
      text-align: center;
      width: 70%;
      margin: 0 auto;

      .title {
        font-size: $heading-1-font-size-lg;
        color: $color-yellow;
        line-height: 80px;
      }
      .desc {
        font-size: 18px;
        padding: 0 0 30px 0;
      }
    }
  }
  .parent_ebc_portfolio_image {
    .images {
      width: 100%;
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c_amazon_ebc_portfolio_section {
    margin: 50px auto 0;
    padding: 20px 0;
    .parent_ebc_portfolio_description {
      text-align: center;
      width: 50%;
      margin: 0 auto;

      .title {
        font-size: $heading-1-font-size-lg;
        color: $color-yellow;
        line-height: 80px;
      }
      .desc {
        font-size: 18px;
        padding: 0 0 30px 0;
      }
    }
  }
  .parent_ebc_portfolio_image {
    .images {
      width: 100%;
    }
  }
}
