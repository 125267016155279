@import "../../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-aam-inventory-management {
    background-color: $color-light-green;
    .im-inner-container {
      text-align: center;
      padding: 10px;

      .inventory-management-header {
        .im-heading {
          font-size: $heading-1-font-size-mb;
        }
        .im-description {
          font-size: $text-font-size-mb;
        }
      }

      .im-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        margin: 20px 0;
      }
    }
  }
  .c-im-card {
    padding: 40px 20px;
    background-color: white;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2);

    .im-card-heading {
      font-size: $heading-4-font-size-mb;
    }
    .im-card-description {
      font-size: $text-font-size-mb;
      text-align: left;
    }

    .im-hr {
      padding: 2px;
      background-color: $color-yellow;
      width: 30%;
      margin: 10px auto;
      border: none;
    }
    .im-list {
      text-align: left;
    }

    .im-for-label {
      font-size: $text-font-size-lg;
    }
    .im-inner-list {
      .list-item {
        display: flex;
        align-items: center;
        font-size: $text-font-size-lg;
        .list-icon {
          font-size: $heading-4-font-size-lg;
          position: relative;
          top: 3px;
          color: $color-yellow;
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-aam-inventory-management {
    background-color: $color-light-green;
    .im-inner-container {
      text-align: center;
      padding: 10px;

      .inventory-management-header {
        .im-heading {
          font-size: $heading-1-font-size-mb;
        }
        .im-description {
          font-size: $text-font-size-mb;
        }
      }

      .im-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        margin: 20px 0;
      }
    }
  }
  .c-im-card {
    padding: 40px 20px;
    background-color: white;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2);

    .im-card-heading {
      font-size: $heading-4-font-size-mb;
    }
    .im-card-description {
      font-size: $text-font-size-mb;
      text-align: left;
    }

    .im-hr {
      padding: 2px;
      background-color: $color-yellow;
      width: 30%;
      margin: 10px auto;
      border: none;
    }
    .im-list {
      text-align: left;
    }

    .im-for-label {
      font-size: $text-font-size-lg;
    }
    .im-inner-list {
      .list-item {
        display: flex;
        align-items: center;
        font-size: $text-font-size-lg;
        .list-icon {
          font-size: $heading-4-font-size-lg;
          position: relative;
          top: 3px;
          color: $color-yellow;
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-aam-inventory-management {
    background-color: #ebfbf7;
    .im-inner-container {
      text-align: center;
      padding: 20px;

      .inventory-management-header {
        .im-heading {
          font-size: $heading-1-font-size-lg;
          line-height: 2em;
        }
        .im-description {
          font-size: $text-font-size-lg;
        }
      }

      .im-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        margin: 20px 0;
      }
    }
  }
  .c-im-card {
    padding: 40px 20px;
    background-color: white;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2);

    .im-card-heading {
      font-size: $heading-4-font-size-lg;
    }
    .im-card-description {
      font-size: $text-font-size-lg;
      text-align: left;
    }

    .im-hr {
      padding: 2px;
      background-color: $color-yellow;
      width: 30%;
      margin: 10px auto;
      border: none;
    }
    .im-list {
      text-align: left;
    }

    .im-for-label {
      font-size: $text-font-size-lg;
    }
    .im-inner-list {
      .list-item {
        display: flex;
        align-items: center;
        font-size: $text-font-size-lg;
        .list-icon {
          font-size: $heading-4-font-size-lg;
          position: relative;
          top: 3px;
          color: $color-yellow;
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-aam-inventory-management {
    background-color: #ebfbf7;
    .im-inner-container {
      width: 90%;
      margin: 0 auto;
      text-align: center;
      padding: 20px;

      .inventory-management-header {
        .im-heading {
          font-size: $heading-1-font-size-lg;
          line-height: 2em;
        }
        .im-description {
          font-size: $text-font-size-lg;
        }
      }

      .im-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        margin: 20px 0;
      }
    }
  }
  .c-im-card {
    padding: 40px 20px;
    background-color: white;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2);

    .im-card-heading {
      font-size: $heading-4-font-size-lg;
    }
    .im-card-description {
      font-size: $text-font-size-lg;
      text-align: left;
    }

    .im-hr {
      padding: 2px;
      background-color: $color-yellow;
      width: 30%;
      margin: 10px auto;
      border: none;
    }
    .im-list {
      text-align: left;
    }

    .im-for-label {
      font-size: $text-font-size-lg;
    }
    .im-inner-list {
      .list-item {
        display: flex;
        align-items: center;
        font-size: $text-font-size-lg;
        .list-icon {
          font-size: $heading-4-font-size-lg;
          position: relative;
          top: 3px;
          color: $color-yellow;
        }
      }
    }
  }
}
