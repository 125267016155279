@import "../../ComponentStyles/components-styles.scss";

@mixin form-field {
  width: 100%;
  padding: 10px;
  font-size: $text-font-size-lg;
  max-width: 100%;
  border: 1px solid $color-gray;
}

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .form-field {
    @include form-field;
    font-size: $text-font-size-mb;
  }
  .textArea {
    @include form-field;
    height: 200px;
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .form-field {
    @include form-field;
  }
  .textArea {
    @include form-field;
    height: 200px;
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .form-field {
    @include form-field;
  }
  .textArea {
    @include form-field;
    height: 200px;
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .form-field {
    @include form-field;
  }
  .textArea {
    @include form-field;
    height: 200px;
  }
}
