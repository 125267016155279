@import "../../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-optimization-audit-product-form {
    padding: 10px;
    background-color: $color-white;
    .error {
      background-color: #e57373;
      margin: 10px 0;
      padding: 10px 20px;
      color: white;
    }
    .success {
      background-color: $color-yellow;
      margin: 10px 0;
      padding: 10px 20px;
      color: white;
    }

    .form {
      .form-heading {
        font-size: $heading-4-font-size-mb;
        text-align: center;
        text-transform: capitalize;
      }
      .form-description {
        text-align: center;
      }
      .form-fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 0px;
        .form-control {
          .form-label {
            display: block;
            margin: 10px 0;
          }
        }
      }

      .submit-btn-container {
        .submit-btn {
          padding: 10px;
          width: 100%;
          margin-top: 10px;
          font-size: $heading-5-font-size-mb;
          background-color: $color-gray;
          border: none;
          color: $color-white;
          &:disabled {
            background-color: #787878 !important;
          }
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-optimization-audit-product-form {
    padding: 20px;
    background-color: $color-white;
    .error {
      background-color: #e57373;
      margin: 10px 0;
      padding: 10px 20px;
      color: white;
    }
    .success {
      background-color: $color-yellow;
      margin: 10px 0;
      padding: 10px 20px;
      color: white;
    }
    .form {
      .form-heading {
        font-size: $heading-4-font-size-mb;
        text-align: center;
        text-transform: capitalize;
      }
      .form-description {
        font-size: $heading-5-font-size-mb;
      }
      .form-description {
        text-align: center;
      }
      .form-fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 0px;
        .form-control {
          .form-label {
            display: block;
            margin: 10px 0;
          }
        }
      }

      .submit-btn-container {
        .submit-btn {
          padding: 10px;
          width: 100%;
          margin-top: 10px;
          font-size: $heading-5-font-size-mb;
          background-color: $color-gray;
          border: none;
          color: $color-white;
          &:disabled {
            background-color: #787878 !important;
          }
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-optimization-audit-product-form {
    padding: 20px;
    background-color: $color-white;
    .error {
      background-color: #e57373;
      margin: 10px 0;
      padding: 10px 20px;
      color: white;
    }
    .success {
      background-color: $color-yellow;
      margin: 10px 0;
      padding: 10px 20px;
      color: white;
    }

    .form {
      .form-heading {
        font-size: $heading-3-font-size-lg;
        text-transform: capitalize;
      }
      .form-description {
        font-size: $heading-4-font-size-lg;
      }
      .form-fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 0px;
        .form-control {
          .form-label {
            display: block;
            margin: 10px 0;
          }
        }
      }

      .submit-btn-container {
        .submit-btn {
          padding: 10px;
          width: 100%;
          margin-top: 10px;
          font-size: $heading-5-font-size-lg;
          background-color: $color-gray;
          border: none;
          color: $color-white;
          &:disabled {
            background-color: #787878 !important;
          }
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-optimization-audit-product-form {
    padding: 20px;
    background-color: $color-white;
    .error {
      background-color: #e57373;
      margin: 10px 0;
      padding: 10px 20px;
      color: white;
    }
    .success {
      background-color: $color-yellow;
      margin: 10px 0;
      padding: 10px 20px;
      color: white;
    }
    .form {
      .form-heading {
        font-size: $heading-3-font-size-lg;
        text-transform: capitalize;
      }
      .form-description {
        font-size: $heading-4-font-size-lg;
      }
      .form-fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 0px;
        .form-control {
          .form-label {
            display: block;
            margin: 10px 0;
          }
        }
      }

      .submit-btn-container {
        .submit-btn {
          padding: 10px;
          width: 100%;
          margin-top: 10px;
          font-size: $heading-5-font-size-lg;
          background-color: $color-gray;
          border: none;
          color: $color-white;

          &:disabled {
            background-color: #787878 !important;
          }
        }
      }
    }
  }
}
