@import "../../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-ALO-what-service-can-do {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin: 20px 0;
    align-items: center;

    .ALO-what-service-can-do-left {
      .heading {
        text-align: center;
        font-size: $heading-1-font-size-mb;
      }
      .description {
        margin: 10px 0;
        text-align: center;
      }
      .faq-accordion {
        h3 {
          text-align: center;
        }
      }
    }

    .ALO-what-service-can-do-right {
      .image {
        .right-image {
          width: 100%;
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-ALO-what-service-can-do {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin: 20px 0;
    align-items: center;

    .ALO-what-service-can-do-left {
      .heading {
        text-align: center;
        font-size: $heading-1-font-size-mb;
      }
      .description {
        margin: 10px 0;
        text-align: center;
      }
      .faq-accordion {
        h3 {
          text-align: center;
        }
      }
    }

    .ALO-what-service-can-do-right {
      .image {
        .right-image {
          width: 100%;
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-ALO-what-service-can-do {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    margin: 40px 0;
    align-items: center;

    .ALO-what-service-can-do-left {
      .heading {
        font-size: $heading-1-font-size-lg;
      }
      .description {
        margin: 10px 0;
      }
    }

    .ALO-what-service-can-do-right {
      .image {
        .right-image {
          width: 100%;
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-ALO-what-service-can-do {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    width: 90%;
    margin: 0 auto;
    align-items: center;
    .ALO-what-service-can-do-left {
      .heading {
        font-size: $heading-1-font-size-lg;
      }
      .description {
        margin: 10px 0;
      }
    }

    .ALO-what-service-can-do-right {
      .image {
        .right-image {
          width: 100%;
        }
      }
    }
  }
}
