@import "../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-schedule-a-call {
    width: 95%;
    margin: 40px auto;
    padding: 20px;
    background-color: $color-yellow;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

    .schedule-heading {
      font-size: $heading-4-font-size-mb;
    }
    .schedule-btn {
      background-color: $color-white;
      border: none;
      font-size: $heading-4-font-size-mb;
      padding: 10px 25px;
      margin: 10px 0;
      font-weight: bold;
      border-radius: 5px;
    }
  }
  .i-frame-container {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    z-index: 100;
    .i-frame {
      width: 90%;
      height: 600px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // left: 5%;
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-schedule-a-call {
    width: 95%;
    margin: 40px auto;
    padding: 20px;
    background-color: $color-yellow;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

    .schedule-heading {
      font-size: $heading-4-font-size-mb;
    }
    .schedule-btn {
      background-color: $color-white;
      border: none;
      font-size: $heading-4-font-size-mb;
      padding: 10px 25px;
      margin: 10px 0;
      font-weight: bold;
      border-radius: 5px;
    }
  }
  .i-frame-container {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    z-index: 100;
    .i-frame {
      width: 90%;
      height: 600px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // left: 5%;
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-schedule-a-call {
    width: 95%;
    margin: 40px auto;
    padding: 20px;
    background-color: $color-yellow;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

    .schedule-heading {
      font-size: $heading-3-font-size-lg;
    }
    .schedule-btn {
      background-color: $color-white;
      border: none;
      font-size: $heading-5-font-size-lg;
      padding: 10px 25px;
      margin: 10px 0;
      font-weight: bold;
      border-radius: 5px;
    }
  }
  .i-frame-container {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    z-index: 100;
    .i-frame {
      width: 90%;
      height: 600px;
      position: fixed;
      top: 50px;
      left: 5%;
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-schedule-a-call {
    width: 90%;
    margin: 40px auto;
    padding: 40px;
    background-color: $color-yellow;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

    .schedule-heading {
      font-size: $heading-2-font-size-lg;
    }
    .schedule-btn {
      background-color: $color-white;
      border: none;
      font-size: $heading-4-font-size-lg;
      padding: 10px 25px;
      margin: 10px 0;
      font-weight: bold;
      border-radius: 5px;
    }
  }
  .i-frame-container {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    z-index: 100;
    .i-frame {
      width: 90%;
      height: 600px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
