@import "../../../ComponentStyles/components-styles.scss";

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c-ppc-faq {
    text-align: center;
    .ppc-faq-container {
      margin: 20px auto;
      background-color: white;
      h1 {
        font-size: $heading-1-font-size-mb;
      }
      .faq-section {
        text-align: left;
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c-ppc-faq {
    text-align: center;
    .ppc-faq-container {
      margin: 20px auto;
      background-color: white;
      h1 {
        font-size: $heading-1-font-size-mb;
      }
      .faq-section {
        text-align: left;
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c-ppc-faq {
    padding: 20px;
    text-align: center;
    .ppc-faq-container {
      margin: 20px auto;
      background-color: white;
      h1 {
        font-size: $heading-1-font-size-lg;
      }
      .faq-section {
        text-align: left;
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c-ppc-faq {
    padding: 20px;
    text-align: center;
    .ppc-faq-container {
      width: 90%;
      margin: 20px auto;
      background-color: white;
      padding: 20px;
      h1 {
        font-size: $heading-1-font-size-lg;
      }
      .faq-section {
        text-align: left;
      }
    }
  }
}
