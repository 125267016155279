@import "../../../ComponentStyles/components-styles.scss";

$PRheader-heading-size: 64px;
$PRheader-heading-size-md: 52px;
$PRheader-heading-size-sm: 40px;
$PRheader-heading-size-xs: 28px;

/* for mobile devices */
@media screen and (min-width: $starting-xs-breakpoint) and (max-width: $ending-xs-breakpoint) {
  .c_main_about_service {
    .parent_about_service {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      grid-row-gap: 0px;
      align-items: center;
      justify-content: center;

      .parent_image_about_service {
        .camera_image {
          width: 100%;
        }
      }

      .parent_about_service_description {
        margin: 0 auto;
        padding: 10px;
        .camera_title {
          font-size: $heading-3-font-size-mb;
          font-weight: 800;
          padding: 0 0 20px 0;
          color: $color-yellow;
          text-align: center;
        }
        .camera_description {
          font-size: $heading-6-font-size-mb;
          font-weight: 400;
          padding: 0 0 30px 0;
          color: #000;
        }
      }
    }
  }
}

/* for tablets devices */
@media screen and (min-width: $starting-md-breakpoint) and (max-width: $ending-md-breakpoint) {
  .c_main_about_service {
    .parent_about_service {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      grid-row-gap: 0px;
      align-items: center;
      justify-content: center;

      .parent_image_about_service {
        .camera_image {
          width: 100%;
        }
      }

      .parent_about_service_description {
        width: 90%;
        margin: 0 auto;
        .camera_title {
          font-size: $PRheader-heading-size-sm;
          font-weight: 800;
          padding: 0 0 20px 0;
          color: $color-yellow;
        }
        .camera_description {
          font-size: $heading-6-font-size-mb;
          font-weight: 400;
          padding: 0 0 30px 0;
          color: #000;
        }
      }
    }
  }
}

/* for laptops devices */
@media screen and (min-width: $starting-lg-breakpoint) and (max-width: $ending-lg-breakpoint) {
  .c_main_about_service {
    padding: 0 20px 0 0;

    .parent_about_service {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-row-gap: 0px;
      align-items: center;
      justify-content: center;

      .parent_image_about_service {
        .camera_image {
          width: 100%;
        }
      }

      .parent_about_service_description {
        .camera_title {
          font-size: $heading-1-font-size-lg;
          font-weight: 800;
          padding: 0 0 20px 0;
          color: $color-yellow;
        }
        .camera_description {
          font-size: $heading-6-font-size-mb;
          font-weight: 400;
          padding: 0 0 30px 0;
          color: #000;
        }
      }
    }
  }
}

/* for large devices */
@media screen and (min-width: $starting-xl-breakpoint) and (max-width: $ending-xl-breakpoint) {
  .c_main_about_service {
    padding: 0 20px 0 0;

    .parent_about_service {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-row-gap: 0px;
      align-items: center;
      justify-content: center;

      .parent_image_about_service {
        .camera_image {
          width: 100%;
        }
      }

      .parent_about_service_description {
        .camera_title {
          font-size: $heading-1-font-size-lg;
          font-weight: 800;
          padding: 0 0 20px 0;
          color: $color-yellow;
        }
        .camera_description {
          font-size: $heading-6-font-size-mb;
          font-weight: 400;
          padding: 0 0 30px 0;
          color: #000;
        }
      }
    }
  }
}
